import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';

import { ModalService, Dictionary, isPermitted } from 'asap-team/asap-tools';

// Consts
import {
  ROUTE,
  USER_ROLE,
  QUERY_PARAM,
  PERMITTED_ACTION,
  SUBSCRIPTION_STATUS,
  BANNERS_PAYWALL_LENDER,
  TRAFFIC_COLLECTION_PLACEHOLDER_COPY,
  BUYERS_BANNERS_AGENT_LOCK_BY_LENDER,
} from '@consts/consts';
import type { Profile } from '@core/types/Profile';

// Services
import { UserService } from '@core/services/user/user.service';

// Components
import { GetInviteLinkComponent } from '@commons/modals/get-invite-link/get-invite-link.component';

@UntilDestroy()
@Component({
  selector: 'buyer-banner-collection-placeholder',
  templateUrl: 'buyer-banner-collection-placeholder.component.html',
  styleUrls: ['buyer-banner-collection-placeholder.component.scss'],
})
export class BuyerBannerCollectionPlaceholderComponent implements OnInit {

  copy: Dictionary;

  profile: Profile;

  PERMITTED_ACTION: Dictionary<string> = PERMITTED_ACTION;

  constructor(
    private router: Router,
    private userService: UserService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.profile = this.userService.syncGetProfile();
    this.copy = this.getCopy();
  }

  get isAppEnabled(): boolean {
    return this.profile.apps.buyer_traffic.enabled;
  }

  get isSubscriptionStatusValid(): boolean {
    const { subscription_status } = this.userService.syncGetProfile();

    return !(
      subscription_status === SUBSCRIPTION_STATUS.PAUSED
      || subscription_status === SUBSCRIPTION_STATUS.MONITORING_PROGRAM
    );
  }

  getCopy(): Dictionary {
    switch (this.userService.getUserRole()) {
      case USER_ROLE.agent: {
        if (this.profile.permitted_actions.includes(PERMITTED_ACTION.BUYER_BANNERS_LOCK_BY_LENDER_UPGRADE)) {
          return BUYERS_BANNERS_AGENT_LOCK_BY_LENDER;
        }

        return TRAFFIC_COLLECTION_PLACEHOLDER_COPY[USER_ROLE.agent];
      }
      case USER_ROLE.lender: {
        if (isPermitted(this.profile.permitted_actions, PERMITTED_ACTION.BUYER_BANNERS_STATE_PROMO)) {
          return BANNERS_PAYWALL_LENDER;
        }

        if (!this.isSubscriptionStatusValid) {
          return BANNERS_PAYWALL_LENDER;
        }

        return TRAFFIC_COLLECTION_PLACEHOLDER_COPY[USER_ROLE.lender];
      }
      default: {
        return null;
      }
    }
  }

  invite(): void {
    switch (this.userService.getUserRole()) {
      case USER_ROLE.agent: {
        this.router.navigate([ROUTE.alias.PARTNERSHIP], { queryParams: { [QUERY_PARAM.INVITE_SIDEBAR]: true } });
        break;
      }
      case USER_ROLE.lender: {
        this.router.navigate([ROUTE.alias.UPGRADE_SUBSCRIPTION]);
        break;
      }
      default: {
        break;
      }
    }
  }

  showGetLinkModal(): void {
    this
      .modalService
      .open(GetInviteLinkComponent)
      .pipe(
        filter(Boolean),
        untilDestroyed(this),
      )
      .subscribe();
  }

  resumeSubscription(): void {
    this.router.navigate([ROUTE.alias.BILLING]);
  }

}
