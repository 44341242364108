import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError, required } from './conditions';

export const editLoanErrors: FormErrors = {
  home_value: [
    required,
    apiError,
  ],
  open_lien_amount: [
    apiError,
  ],
  sale_date: [
    apiError,
  ],
  loan_amortization_period: [
    apiError,
  ],
  global: [],
};
