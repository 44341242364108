import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';

import {
  ModalService,
  Dictionary,
  CollectionResponse,
} from 'asap-team/asap-tools';

// Consts
import { environment } from 'environments/environment';
import {
  ROUTE,
  USER_ROLE,
  USER_LABEL,
  QUERY_PARAM,
  BILLING_PLAN,
  PERMITTED_ACTION,
  SUBSCRIPTION_STATUS,
  BUYERS_LEADS_PLACEHOLDER,
  BUYERS_LEADS_PLACEHOLDER_AGENT,
  BUYERS_LEADS_AGENT_LOCK_BY_LENDER,
  TRAFFIC_COLLECTION_PLACEHOLDER_COPY,
  BUYERS_LEADS_PLACEHOLDER_AGENT_PAYWALL,
} from '@consts/consts';
import type { Profile } from '@core/types/Profile';

// Services
import { UserService } from '@core/services/user/user.service';
import { ActivePartnersService } from '@core/services/collections/partnership/active-partners.service';

// Components
import { YoutubeVideoComponent } from '@commons/modals/youtube-video/youtube-video.component';
import { GetInviteLinkComponent } from '@commons/modals/get-invite-link/get-invite-link.component';
import { WINDOW } from '@ng-web-apis/common';
import { ActivePartner } from '@core/types';

@UntilDestroy()
@Component({
  selector: 'buyer-collection-placeholder',
  templateUrl: 'buyer-collection-placeholder.component.html',
  styleUrls: ['buyer-collection-placeholder.component.scss'],
})
export class BuyerCollectionPlaceholderComponent implements OnInit {

  @Input() isLenderEmptyState: boolean = false;

  copy: Dictionary;

  profile: Profile;

  landingUrl: string;

  PERMITTED_ACTION: Dictionary<string> = PERMITTED_ACTION;

  constructor(
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private userService: UserService,
    private modalService: ModalService,
    private activePartnersService: ActivePartnersService,
  ) { }

  ngOnInit(): void {
    this.profile = this.userService.syncGetProfile();
    this.copy = this.getCopy();
    this.setLandingUrl();
  }

  get isAppEnabled(): boolean {
    return this.profile.apps.buyer_traffic.enabled;
  }

  get isSubscriptionStatusValid(): boolean {
    const { subscription_status } = this.profile;

    return !(
      subscription_status === SUBSCRIPTION_STATUS.PAUSED
      || subscription_status === SUBSCRIPTION_STATUS.MONITORING_PROGRAM
    );
  }

  get isLimitedAgentPlans(): boolean {
    return (
      this.userService.getUserBillingPlan() === BILLING_PLAN.SOLO_PLAN
      || this.userService.getUserBillingPlan() === BILLING_PLAN.TRIAL
    );
  }

  setLandingUrl(): void {
    this.activePartnersService
      .getCollection({ page: '1', per_page: '10' })
      .pipe(untilDestroyed(this))
      .subscribe((partners: CollectionResponse<ActivePartner>) => {
        let primaryPartner: ActivePartner = null;

        if (this.profile.role === USER_ROLE.lender) {
          primaryPartner = partners.data.find((partner: ActivePartner) => partner.role === USER_LABEL.loan_officer_title);
        }

        if (this.profile.role === USER_ROLE.agent) {
          primaryPartner = partners?.data.find((partner: ActivePartner) => partner.primary);
        }

        const partnerUrl: string = primaryPartner ? `${primaryPartner.username}?partner=${this.profile.username}` : this.profile.username;

        this.landingUrl = `${environment.buyerIQ}/${partnerUrl}`;
      });
  }

  getCopy(): Dictionary {
    switch (this.userService.getUserRole()) {
      case USER_ROLE.agent: {
        if (this.isLimitedAgentPlans) {
          return BUYERS_LEADS_PLACEHOLDER_AGENT_PAYWALL;
        }

        if (this.profile.permitted_actions.includes(PERMITTED_ACTION.BUYER_LEADS_LOCK_BY_LENDER_UPGRADE)) {
          return BUYERS_LEADS_AGENT_LOCK_BY_LENDER;
        }

        return this.isLenderEmptyState ? BUYERS_LEADS_PLACEHOLDER_AGENT : TRAFFIC_COLLECTION_PLACEHOLDER_COPY[USER_ROLE.agent];
      }
      case USER_ROLE.lender: {
        return this.isLenderEmptyState ? BUYERS_LEADS_PLACEHOLDER : TRAFFIC_COLLECTION_PLACEHOLDER_COPY[USER_ROLE.lender];
      }
      default: {
        return null;
      }
    }
  }

  invite(): void {
    switch (this.userService.getUserRole()) {
      case USER_ROLE.agent: {
        this.router.navigate([ROUTE.alias.PARTNERSHIP], { queryParams: { [QUERY_PARAM.INVITE_SIDEBAR]: true } });
        break;
      }
      case USER_ROLE.lender: {
        this.router.navigate([ROUTE.alias.BUYERS_LP_PREVIEW]);
        break;
      }
      default: {
        break;
      }
    }
  }

  resumeSubscription(): void {
    this.router.navigate([ROUTE.alias.BILLING]);
  }

  showGetLinkModal(): void {
    if (this.isLenderEmptyState && !this.isLimitedAgentPlans) {
      this.window.open(this.landingUrl, '_blank').focus();

      return;
    }

    this.modalService
      .open(GetInviteLinkComponent)
      .pipe(filter(Boolean), untilDestroyed(this))
      .subscribe();
  }

  showVideoModal(): void {
    const id: any = 'https://www.youtube.com/embed/0AsIW77E3Dc';

    this.modalService.open(YoutubeVideoComponent, { id });
  }

}
