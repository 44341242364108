import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output,
} from '@angular/core';
import { includes } from 'lodash-es';

// Types
import type { LeadInfo } from '@core/types';
import { Dictionary } from 'asap-team/asap-tools';

// Consts
import { LEAD_STATUS, PERMITTED_ACTION, PROPERTY_IMAGE_PLACEHOLDER, ROUTE } from '@consts/consts';

@Component({
  selector: 'collection-cell-home-info',
  templateUrl: './home-info.component.html',
  styleUrls: ['./home-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionCellHomeInfoComponent {

  @Input() type: 'report' | 'traffic' | 'mortgage' | 'archived' | 'closings' | 'home_shopper';

  @Input() lead: LeadInfo;

  @Output()
  selectRowEmitter: EventEmitter<void> = new EventEmitter();

  PERMITTED_ACTION: Dictionary = PERMITTED_ACTION;

  get leadDetailsLink(): string[] {
    if (this.lead?.status === LEAD_STATUS.DRAFT) {
      return [ROUTE.alias.HOMEOWNERS_REPORT_NEW];
    }

    const leadPageRouteCommands: any[] = [ROUTE.alias.HOMEOWNERS_LEAD, this.lead?.id];

    if (this.type === 'mortgage') {
      leadPageRouteCommands.push(ROUTE.alias.REFINANCE);
    }

    return this.lead?.overlimit_trial_lead ? [ROUTE.alias.UPGRADE_SUBSCRIPTION] : leadPageRouteCommands;
  }

  ROUTE: any = ROUTE;

  formatAddress(address: string, overlimit_trial_lead: boolean, unit?: string): string {
    if (overlimit_trial_lead) { return address; }

    if (!address) { return ''; }

    let aprt: string = '';

    if (unit) {
      aprt = `, ${unit}`;
    }

    const street: string = address.split(', ').shift();

    return `${street}${aprt}`;
  }

  isLeadContainsPreview(): boolean {
    if (!this.lead?.image) { return false; }

    return this.lead.image !== PROPERTY_IMAGE_PLACEHOLDER;
  }

  getPreviewImage(): string {
    return this.lead?.image || '';
  }

  isValid(type: string, lead: LeadInfo): boolean {
    return !lead[type] || includes(lead?.tooltips?.contacts, `${type.charAt(0).toUpperCase() + type.slice(1)} is incorrect`);
  }

  getDate(lead: LeadInfo): string {
    if (!lead) { return ''; }

    const { sale_date, loan_date } = lead;

    return sale_date || loan_date;
  }

}
