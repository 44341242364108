import { ROUTE } from '@consts/routes';
import type { Dictionary } from 'asap-team/asap-tools';

export const COLLECTION_PLACEHOLDER_COPY: any = {
  not_subscribed: {
    title: 'You are not subscribed to&nbsp;𝘮𝘺HomeIQ report',
    subtitle: 'Watch video below to learn how <i>my</i>𝘮𝘺HomeIQ can help you grow<br>your repeat and referral business',
  },
  collection_empty: {
    title: 'Welcome to 𝘮𝘺HomeIQ',
    subtitle: 'To get started, watch the quick video below and see how you can<br>create your first <i>my</i>HomeIQ report.',
  },
};

export const TRAFFIC_COLLECTION_PLACEHOLDER_COPY: any = {
  agent: {
    title: 'Invite your lender and get access to high converting seller funnels',
    subtitle: 'There is no cost to you for unlocking your 𝘮𝘺HomeIQ account, simply invite the lender you work and gain access to all the premium features 𝘮𝘺HomeIQ has to offer.',
    button: 'Invite your lender',
    outlineButton: 'Get invitation link',
  },
  lender: {
    title: 'Capture motivated buyer leads with automated pre-qualifications',
    subtitle: '𝘮𝘺HomeIQ offers a high converting landing page funnel that allows online buyers to pre-qualify for over 16 different loan programs. Click the video below to see how it works.',
    button: 'Customize landing page',
    outlineButton: 'View your landing page',
  },
};

export const HOMEOWNERS_LEADS_PLACEHOLDER: any = {
  title: 'Use your branded landing page to generate refi leads',
  subtitle: '𝘮𝘺HomeIQ offers a proven formula to generate and nurture your online refi leads. Click the video below to see how it works.',
  button: 'Customize landing page',
  outlineButton: 'View your landing page',
};

export const HOMEOWNERS_LEADS_PLACEHOLDER_SELLER: any = {
  title: 'Use your branded landing page to generate seller leads',
  subtitle: '𝘮𝘺HomeIQ offers a proven formula to generate and nurture your online seller leads. Click the video below to see how it works.',
  button: 'Customize landing page',
  outlineButton: 'View your landing page',
};

export const HOMEOWNERS_LEADS_PLACEHOLDER_AGENT_PAYWALL: any = {
  title: 'Invite your loan officer and get access to high converting seller funnels',
  subtitle: 'There is no cost to you for unlocking your 𝘮𝘺HomeIQ account, simply invite the lender you work and gain access to all the premium features 𝘮𝘺HomeIQ has to offer.',
  button: 'Invite loan officer',
  outlineButton: 'Get invitation link',
};

export const HOMEOWNERS_LEADS_PLACEHOLDER_AGENT_PREMIUM: any = {
  title: 'Use your branded landing pages to generate seller leads',
  subtitle: '𝘮𝘺HomeIQ offers a proven formula to generate and nurture your online seller leads. Click the video below to see how it works.',
  homeWealthButton: 'Home Wealth',
  homeValueButton: 'Home Value',
};

export const BUYERS_LEADS_PLACEHOLDER: any = {
  title: 'Capture motivated buyer leads with automated pre-qualifications',
  subtitle: '𝘮𝘺HomeIQ offers a high converting landing page funnel that allows online buyers to pre-qualify for over 16 different loan programs. Click the video below to see how it works.',
  button: 'Customize landing page',
  outlineButton: 'View your landing page',
};

export const BUYERS_LEADS_PLACEHOLDER_AGENT: any = {
  title: 'Capture motivated buyer leads with automated pre-qualifications',
  subtitle: '𝘮𝘺HomeIQ offers a high converting landing page funnel that allows online buyers to pre-qualify for over 16 different loan programs. Click the video below to see how it works.',
  outlineButton: 'View your landing page',
};

export const BUYERS_LEADS_PLACEHOLDER_AGENT_PAYWALL: any = {
  title: 'Invite your lender and get access to high converting buyer funnels',
  subtitle: 'There is no cost to you for unlocking your 𝘮𝘺HomeIQ account, simply invite the lender you work and gain access to all the premium features 𝘮𝘺HomeIQ has to offer.',
  button: 'Invite loan officer',
  outlineButton: 'Get invitation link',
};

export const DT_EMPTY_STATE_TEMPLATE: Dictionary = {
  emptyMessage: `
    <div class="empty-data-table">
      <img src="assets/icons/data-table/empty_img.svg">
      <h2 class="empty-data-table__title">No results found. <br/> Please try a different search query.</h2>
    </div>
  `,
};

export const INTELLIGENCE_COLLEAGUES_EMPTY_STATE_TEMPLATE: Dictionary = {
  emptyMessage: `
    <div class="empty-data-table">
      <div class="empty-data-table__description">
        <div class="empty-data-table__description--image">
          <img src="assets/icons/data-table/empty_agent_table_img_v2.svg">
        </div>
        <div class="empty-data-table__description--text">
          <h2 class="empty-data-table__description--text-title">We couldn't find any of your agent colleagues.</h2>
          <p class="m-b-8">It can happen for two main reasons:</p>
          <ul class="empty-data-table__description--text-list">
            <li>
              License number entered in your myhomeIQ profile is incorrect or has invalid format.
              <a class="empty-data-table__link" href="${ROUTE.alias.PROFILE}">Verify license number</a>
            </li>
            <li>
              We have limited coverage in your license area.
              <a class="empty-data-table__link" rel="show-coverage-map">View details</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  `,
};

export const INTELLIGENCE_COLLEAGUES_SEARCH_RESULTS_EMPTY: Dictionary = {
  emptyMessage: `
    <div class="empty-data-table">
      <img src="assets/icons/data-table/empty_agent_table_img_v2.svg">
      <h2 class="empty-data-table__title f-14-normal l-h-22">Can’t find any of your agent colleagues.</h2>
      <a class="empty-data-table__link f-14-600 l-h-22" href="${ROUTE.alias.INTELLIGENCE_AGENT_SEARCH}">Try Agent Search</a>
    </div>
  `,
};

export const INTELLIGENCE_REALTORS_EMPTY_STATE_TEMPLATE: Dictionary = {
  emptyMessage: `
    <div class="empty-data-table">
      <img src="assets/icons/data-table/empty_agent_table_img_v2.svg">
      <h2 class="empty-data-table__title">Can’t find any agent.</h2>
    </div>
  `,
};

export const DT_EMPTY_STATE_NO_LEADS_TEMPLATE: Dictionary = {
  emptyMessage: `
    <div class="empty-data-table">
      <img src="assets/icons/data-table/empty_img.svg">
      <h2 class="empty-data-table__title">You have no leads yet</h2>
    </div>
  `,
};

export const BANNERS_PAYWALL_LENDER: Dictionary = {
  title: 'Get access to marketing banners <br/> to capture online leads',
  subtitle: 'Renew your subscription and get access to marketing banners',
  button: 'Renew subscription',
};

export const HOMEOWNERS_LEADS_PLACEHOLDER_LENDER_PAYWALL: Dictionary = {
  title: 'Use your branded landing pages to generate refi and seller leads',
  subtitle: '𝘮𝘺HomeIQ offers a proven formula to generate and nurture your online refi and seller leads. Click the video below to see how it works.',
  button: 'Renew subscription',
};

export const HOMEOWNERS_LEADS_PLACEHOLDER_LENDER_PREMIUM: any = {
  title: 'Use your branded landing pages to generate leads',
  subtitle: '𝘮𝘺HomeIQ offers a proven formula to generate and nurture your online refi and homeowner leads. Click the video below to see how it works.',
  homeWealthButton: 'View home wealth website',
  homeValueButton: 'View refinance website',
  button: 'Customize landing page',
};

export const BUYERS_LEADS_AGENT_LOCK_BY_LENDER: any = {
  title: 'Capture motivated buyer leads with <br/> automated pre-qualifications',
  subtitle: 'This feature currently unavailable on your loan officer’s subscription. <br/> If you want to start or continue using this feature, ask your loan officer <br/> to upgrade their subscription',
};

export const BUYERS_BANNERS_AGENT_LOCK_BY_LENDER: any = {
  title: 'Get access to marketing banners <br/> to capture online leads',
  subtitle: 'This feature currently unavailable on your loan officer’s subscription.<br/>If you want to start or continue using this feature, ask your loan officer<br/>to upgrade their subscription',
};
