import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { values } from 'lodash-es';
import { BaseHttpService, uID } from 'asap-team/asap-tools';

import type { ZipCode } from '@core/types';

@Injectable({ providedIn: 'root' })
export class ZipCodesService {

  constructor(
    private http: BaseHttpService,
  ) {}

  search(zipCode: string): Observable<ZipCode[]> {
    if (zipCode === '') {
      return of([]);
    }

    return this
      .http
      .get(`v2/zip_codes/search?zip_code=${zipCode}`);
  }

  lookup(type: string): Observable<ZipCode[]> {
    return this
      .http
      .get(`v2/zip_codes/lookup?type=${type}`)
      .pipe(
        map((response: string[]) => values(response)
          .filter((value: string) => value !== 'zip_codes')
          .map((value: string) => {
            return {
              id: uID(),
              zip_code: value,
              selected: false,
            };
          })),
      );
  }

}
