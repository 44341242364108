import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

// Consts
import { ROUTE } from '@consts/consts';

@Component({
  selector: 'collection-cell-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionCellUserInfoComponent {

  @Input() type: string;

  @Input() row: any;

  ROUTE: any = ROUTE;

  get isUserBlocked(): boolean {
    return this.row?.status === 'blocked';
  }

  get accountLink(): string[] {
    if (this.type === 'uploads') {
      return [ROUTE.alias.ACCOUNT, this.row?.user_id];
    }

    return [ROUTE.alias.ACCOUNT, this.row?.id];
  }

}
