<div class="filter-dropdown" dropdown #areaFilter="bs-dropdown" [insideClick]="true">
  <iq-button size="40" color="grey" iconRight="#icon-chevron" (onClick)="lookup()" dropdownToggle>
    <span class="filter-dropdown__toggle--label" [class.selected]="!isShowLabel">Area</span>
    <span class="filter-dropdown__toggle--values" *ngIf="!isShowLabel">{{ selected }}</span>
  </iq-button>
  <div class="filter-dropdown__menu dropdown-menu" *dropdownMenu>
    <div class="filter-dropdown__menu--container">
      <h4 class="filter-dropdown__menu--title">Area</h4>
      <div class="filter-dropdown-spinner" *ngIf="isLoading">
        <loading-dots class="visible"></loading-dots>
      </div>
      <form class="filter-dropdown__content scrollbar" #form>
        <div class="checkbox" *ngIf="!isLoading && !isShowLabel">
          <label class="checkbox__label text-overflow" (click)="clear()">All</label>
        </div>
        <div class="checkbox" *ngFor="let area of areas">
          <input class="checkbox__input" type="checkbox" [attr.id]="'area_' + area.id" (click)="select(area)"/>
          <label class="checkbox__label text-overflow"
                 [attr.for]="'area_' + area.id"
                 [attr.title]="area.zip_code">
            {{ area.zip_code }}
          </label>
        </div>
      </form>
      <div class="filter-modal-close inline-svg" [inlineSVG]="'#icon-close'" (click)="areaFilter.hide()"></div>
    </div>
  </div>
</div>
