import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ModalService, Dictionary, isPermitted } from 'asap-team/asap-tools';

// Consts
import { environment } from 'environments/environment';
import {
  ROUTE,
  USER_ROLE,
  QUERY_PARAM,
  BILLING_PLAN,
  PERMITTED_ACTION,
  HOMEOWNERS_LEADS_PLACEHOLDER,
  HOMEOWNERS_LEADS_PLACEHOLDER_SELLER,
  HOMEOWNERS_LEADS_PLACEHOLDER_AGENT_PAYWALL,
  HOMEOWNERS_LEADS_PLACEHOLDER_AGENT_PREMIUM,
  HOMEOWNERS_LEADS_PLACEHOLDER_LENDER_PAYWALL,
  HOMEOWNERS_LEADS_PLACEHOLDER_LENDER_PREMIUM,
} from '@consts/consts';
import type { Profile } from '@core/types/Profile';

// Services
import { UserService } from '@core/services/user/user.service';

// Components
import { YoutubeVideoComponent } from '@commons/modals/youtube-video/youtube-video.component';
import { WINDOW } from '@ng-web-apis/common';
import { GetInviteLinkComponent } from '@commons/modals/get-invite-link/get-invite-link.component';

@UntilDestroy()
@Component({
  selector: 'traffic-collection-placeholder',
  templateUrl: 'traffic-collection-placeholder.component.html',
  styleUrls: ['traffic-collection-placeholder.component.scss'],
})
export class TrafficCollectionPlaceholderComponent implements OnInit {

  copy: Dictionary;

  profile: Profile;

  public readonly PERMITTED_ACTION: Dictionary = PERMITTED_ACTION;

  constructor(
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private userService: UserService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.profile = this.userService.syncGetProfile();
    this.copy = this.getCopy();
  }

  get getBackgroundImg(): string {
    return 'assets/components/collections/traffic/traffic-placeholder-2.png';
  }

  get lenderLendingUrl(): string {
    return `${environment.herUrl}/${this.profile.username}`;
  }

  get isAppEnabled(): boolean {
    return this.profile.apps.seller_traffic.enabled;
  }

  get isLimitedAgentPlans(): boolean {
    return (
      this.userService.getUserRole() === USER_ROLE.agent
      && (this.userService.getUserBillingPlan() === BILLING_PLAN.SOLO_PLAN
        || this.userService.getUserBillingPlan() === BILLING_PLAN.TRIAL)
    );
  }

  get isTrialPlan(): boolean {
    return this.userService.getUserBillingPlan() === BILLING_PLAN.TRIAL;
  }

  getCopy(): Dictionary {
    switch (this.userService.getUserRole()) {
      case USER_ROLE.agent: {
        if (this.isLimitedAgentPlans) {
          return HOMEOWNERS_LEADS_PLACEHOLDER_AGENT_PAYWALL;
        }

        if (isPermitted(this.profile.permitted_actions, PERMITTED_ACTION.HOME_WEALTH_LANDING_MANAGE)) {
          return HOMEOWNERS_LEADS_PLACEHOLDER_AGENT_PREMIUM;
        }

        return HOMEOWNERS_LEADS_PLACEHOLDER_SELLER;
      }
      case USER_ROLE.lender: {
        // eslint-disable-next-line max-len
        if (this.userService.isUserLimitedByPaywall() || isPermitted(this.profile.permitted_actions, PERMITTED_ACTION.HOMEOWNER_LEADS_LOCK_SUBSCRIPTION_RENEW)) {
          return HOMEOWNERS_LEADS_PLACEHOLDER_LENDER_PAYWALL;
        }

        if (this.isTrialPlan) {
          return HOMEOWNERS_LEADS_PLACEHOLDER;
        }

        return HOMEOWNERS_LEADS_PLACEHOLDER_LENDER_PREMIUM;
      }
      default: {
        return null;
      }
    }
  }

  invite(): void {
    if (this.isLimitedAgentPlans) {
      this.router.navigate([ROUTE.alias.PARTNERSHIP], { queryParams: { [QUERY_PARAM.INVITE_SIDEBAR]: true } });
      // eslint-disable-next-line max-len
    } else if (this.userService.isUserLimitedByPaywall() || isPermitted(this.profile.permitted_actions, PERMITTED_ACTION.HOMEOWNER_LEADS_LOCK_SUBSCRIPTION_RENEW)) {
      this.router.navigate([ROUTE.alias.BILLING]);
    } else {
      this.router.navigate([ROUTE.alias.HOMEOWNERS_SETTINGS_LANDING_PAGES]);
    }
  }

  showGetLinkModal(): void {
    if (this.isLimitedAgentPlans) {
      this.modalService
        .open(GetInviteLinkComponent)
        .pipe(filter(Boolean), untilDestroyed(this))
        .subscribe();
    } else {
      this.goToHomeValueLanding();
    }
  }

  goToHomeValueLanding(): void {
    this.window.open(this.lenderLendingUrl, '_blank').focus();
  }

  goToHomeWealthLanding(): void {
    this.window.open(`${this.lenderLendingUrl}/home-value`, '_blank').focus();
  }

  showVideoModal(): void {
    this.modalService.open(YoutubeVideoComponent, { id: 'https://www.youtube.com/embed/RL4UNLc3Pgg' });
  }

}
