import { Injectable } from '@angular/core';

// Consts
import { USER_ROLE } from '@consts/user';
import { environment } from 'environments/environment';

// Services
import { UserService } from '@core/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class CalendlyService {

  constructor(private userService: UserService) {}

  hasAssignedStaffTrainingUrl(): boolean {
    return !!this.userService.syncGetProfile()?.assigned_staff?.webinar_url;
  }

  hasAssignedStaffDemoUrl(): boolean {
    return !!this.userService.syncGetProfile()?.assigned_staff?.demo_url;
  }

  getDemoUrl(): string {
    if (this.hasAssignedStaffDemoUrl()) {
      return this.userService.syncGetProfile()?.assigned_staff?.demo_url;
    }

    return this.getDefaultUrl();
  }

  getTrainingUrl(): string {
    if (this.hasAssignedStaffTrainingUrl()) {
      return this.userService.syncGetProfile()?.assigned_staff?.webinar_url;
    }

    if (this.userService.isUserRole(USER_ROLE.lender)) {
      return environment.calendlyLinks.lenderOnboarding;
    }

    return this.getDefaultUrl();
  }

  getDefaultUrl(): string {
    switch (this.userService.getUserRole()) {
      case USER_ROLE.agent: {
        return environment.calendlyLinks.agent;
      }
      case USER_ROLE.lender: {
        return environment.calendlyLinks.lender;
      }
      default: {
        return environment.calendlyLinks.default;
      }
    }
  }

}
