import momentMini from 'moment-mini';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/ngx-bootstrap-datepicker';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { forEach, isEqual } from 'lodash-es';

import type { DateRange } from '@core/types';

// Consts
import { API_DATE_FORMAT, DEFAULT_MOMENT_DATE_FORMAT } from '@consts/consts';

// Utils
import { getDefaultDateRanges } from '@core/utils/utils';

@UntilDestroy()
@Component({
  selector: 'iq-date-range-filter',
  templateUrl: './iq-date-range-filter.component.html',
  styleUrls: ['./iq-date-range-filter.component.scss'],
})
export class IqDateRangeFilterComponent {

  @Input() title: string;

  @Input() filterName: string = '';

  @Input() stateValueFrom: string;

  @Input() stateValueTo: string;

  @Output() emitFilterValue: EventEmitter<{ filterName: string; value: any }> = new EventEmitter();

  defaultDateRanges: DateRange[] = getDefaultDateRanges();

  bsInlineRangeValue: Date[] = [];

  bsConfig: Partial<BsDatepickerConfig> = {
    showWeekNumbers: false,
    containerClass: 'date-range',
    dateInputFormat: DEFAULT_MOMENT_DATE_FORMAT,
    maxDate: new Date(),
    showPreviousMonth: true,
  };

  isShow: boolean = false;

  private resetRanges(isResetRanges: boolean): void {
    forEach(this.defaultDateRanges, (range: DateRange) => {
      // eslint-disable-next-line no-param-reassign
      range.active = false;
    });

    if (isResetRanges) {
      this.bsInlineRangeValue = [];
    }
  }

  selectCommonRange({ date_from, date_to }: DateRange, index: number): void {
    this.bsInlineRangeValue = [date_from, date_to];
    this.resetRanges(false);
    this.defaultDateRanges[index].active = true;
  }

  formatDate(date: Date): string {
    return momentMini(date).format(API_DATE_FORMAT);
  }

  get selected(): string {
    const [date_from, date_to] = this.bsInlineRangeValue;

    if (date_from && date_to && !isEqual(date_from, date_to)) {
      return [
        this.formatDate(date_from),
        ' - ',
        this.formatDate(date_to),
      ].join('');
    }

    return '';
  }

  toggle(): void {
    this.isShow = !this.isShow;
  }

  hide(): void {
    this.isShow = false;
  }

  apply([date_from, date_to]: Date[]): void {
    this.emitFilterValue.emit({
      filterName: this.filterName,
      value: {
        [`${this.filterName}_from`]: momentMini(date_from).format(API_DATE_FORMAT),
        [`${this.filterName}_to`]: momentMini(date_to).format(API_DATE_FORMAT),
      },
    });

    this.hide();
  }

  cancel(): void {
    this.emitFilterValue.emit({
      filterName: this.filterName,
      value: {
        [`${this.filterName}_from`]: null,
        [`${this.filterName}_to`]: null,
      },
    });

    this.resetRanges(true);
    this.hide();
  }

}
