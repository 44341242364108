import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, Input, OnInit } from '@angular/core';
import { map, debounceTime, filter } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';

import type { Dictionary } from 'asap-team/asap-tools';
import { Store } from '@core/types/Store';

@UntilDestroy()
@Component({
  selector: 'simple-search-filter',
  templateUrl: './simple-search-filter.component.html',
  styleUrls: ['./simple-search-filter.component.scss'],
})
export class SimpleSearchFilterComponent implements OnInit {

  @Input() provider: Store;

  form: FormGroup = this.fb.group({ query: [''] });

  constructor(
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.subscribes();
  }

  private subscribes(): void {
    this
      .provider
      .queryParams$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        (filters: Dictionary<string>) => {
          if (!filters) { return; }

          if (!filters.search) {
            this.reset();
          }
        },
      );

    this
      .form
      .valueChanges
      .pipe(
        map((event: any) => event.query),
        filter((value: string) => value?.length > 2 || value?.length === 0),
        debounceTime(500),
        untilDestroyed(this),
      )
      .subscribe((query: string) => {
        this.search(query);
      });
  }

  private reset(): void {
    this.form.get('query').patchValue('', { emitEvent: false });
  }

  search(value: string): void {
    this.provider.emitFilters({ search: value ? value.trim() : '' });
  }

}
