import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, throwError } from 'rxjs';
import {
  Dictionary,
  FileReaderService,
  lodashToTitle,
  uID,
  ModalService,
  FormErrors,
} from 'asap-team/asap-tools';

import type {
  CheckboxItem,
  CollectionStaffUser,
  ConfirmActionModalCopy,
  UserRole,
} from '@core/types';

// Consts
import {
  COMMON_TOAST,
  USER_ROLE,
  EMAIL_PATTERN,
  CONFIRM_ACTION_MODAL_COPY,
  staffErrors,
} from '@consts/consts';

// Components
import { ConfirmActionCommonComponent } from '@commons/modals/confirm-action-common/confirm-action-common.component';

// Services
import { StaffUserService } from '@core/services/details/staff-user/staff-user.service';
import { DictionaryService } from '@core/services/dictionary/dictionary.service';

@UntilDestroy()
@Component({
  selector: 'staff-form',
  templateUrl: './staff-form.component.html',
  styleUrls: ['./staff-form.component.scss'],
})
export class StaffFormComponent implements OnInit {

  @Input() type: 'new' | 'edit';

  @Input() user: CollectionStaffUser;

  @Input() companyCode: string;

  @Input() backUrl: string;

  USER_ROLE: Dictionary<UserRole> = USER_ROLE;

  form: FormGroup;

  preview: string | ArrayBuffer;

  previewBackup: string;

  loading: boolean = false;

  saveAction$: Subscription;

  deleteAction$: Subscription;

  timeZones$: Observable<CheckboxItem []> = this.dictionaryService.timeZones$;

  formErrors: FormErrors = staffErrors;

  roles: CheckboxItem[] = [
    USER_ROLE.sales,
    USER_ROLE.owner,
    USER_ROLE.marketing,
    USER_ROLE.customer_success,
  ].map((role: string) => {
    return {
      code: role,
      name: lodashToTitle(role),
      uid: uID(),
    };
  });

  get isHomeiq(): boolean {
    return this.companyCode !== 'home-iq';
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private modalService: ModalService,
    private fileReaderService: FileReaderService,
    private staffUserService: StaffUserService,
    private dictionaryService: DictionaryService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.preview = this.user?.avatar?.medium;
    this.previewBackup = this.user?.avatar?.medium;
  }

  onImageSelected(imageEvent: { image: Blob; name: string }): void {
    this.setImagePreview(imageEvent.image);

    if (this.type === 'edit') {
      this.changeAvatar(imageEvent);

      return;
    }

    this.form.patchValue({ avatar: imageEvent.image });
  }

  submit(): void {
    switch (this.type) {
      case 'edit': {
        this.editUser();
        break;
      }
      case 'new': {
        this.createUser();
        break;
      }
      default: {
        break;
      }
    }
  }

  createUser(): void {
    this.saveAction$ = this
      .staffUserService
      .createStaffUser(this.form.value, this.companyCode)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.toastr.success(COMMON_TOAST.SUCCESS);
          this.router.navigateByUrl(this.backUrl);
        },
      );
  }

  editUser(): void {
    this.saveAction$ = this
      .staffUserService
      .updateStaffUserDetails(this.user.id, this.form.value, this.companyCode)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.toastr.success(COMMON_TOAST.SAVED);
          this.router.navigateByUrl(this.backUrl);
        },
      );
  }

  deleteUser(): void {
    const modalData: ConfirmActionModalCopy = CONFIRM_ACTION_MODAL_COPY.DELETE_STAFF_USER;

    this.deleteAction$ = this
      .modalService
      .open(ConfirmActionCommonComponent, { modalData })
      .pipe(
        untilDestroyed(this),
        filter(Boolean),
        switchMap(() => this.staffUserService.deleteStaffUser(this.user.id, this.companyCode)),
      )
      .subscribe(
        () => {
          this.toastr.success(COMMON_TOAST.REMOVED);
          this.router.navigateByUrl(this.backUrl);
        },
      );
  }

  setCopyStatus(): void {
    this.toastr.success(COMMON_TOAST.COPIED_TO_CLIPBOARD);
  }

  private setImagePreview(image: Blob): void {
    this
      .fileReaderService
      .readAsDataURL(image)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((result: string | ArrayBuffer) => {
        this.preview = result;
      });
  }

  private changeAvatar(payload: { image: Blob; name: string }): void {
    this.loading = true;
    this
      .staffUserService
      .changeStaffAvatar(this.user.id, payload, this.companyCode)
      .pipe(
        catchError((error: any) => {
          this.loading = false;
          this.preview = this.previewBackup;

          return throwError(error);
        }),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.loading = false;
        this.toastr.success(COMMON_TOAST.SUCCESS);
      });
  }

  private initForm(): void {
    this.form = this.fb.group({
      avatar: [''],
      name: [this.user?.name || null, [Validators.required, Validators.minLength(3)]],
      email: [this.user?.email || null, [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      password: [null],
      time_zone: [this.user?.time_zone || null],
    });

    if (!this.isHomeiq) {
      this.form.addControl('role', this.fb.control([this.user?.role || this.USER_ROLE.sales]));
    }
  }

}
