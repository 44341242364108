import type { Dictionary, Page } from 'asap-team/asap-tools';

export const COLLECTION_COUNTER_NAME: Dictionary = {
  // user
  SELLER_DIGEST: 'seller_digest_leads',
  SELLER_TRAFFIC: 'seller_traffic_leads',
  BUYER_TRAFFIC: 'buyer_traffic_leads',
  PENDING_LEADS: 'pending_leads',
  ARCHIVE: 'archive',
  MORTGAGE_CHECKUPS: 'mortgage_checkups',

  // admin
  ACCOUNTS: 'accounts',
  TRIAL_USERS: 'trial_users',
  VIRAL_USERS: 'viral_users',
  LIST_REPORTS_USERS: 'list_reports_users',
  ARCHIVED_USERS: 'archived_users',
  PROMO_CODES: 'promo_codes',
  UNCLAIMED_UPLOADED_DATABASES: 'unclaimed_uploaded_databases',

  // notifications
  NEW_SELLER_TRAFFIC: 'new_seller_traffic_leads_counter',
  PARTNERSHIPS_INCOMING_COUNTER: 'total_partnerships_incoming_counter',
};

export const DEFAULT_PAGE_CONFIG: Page = {
  per_page: 50,
  total_objects: 0,
  total_pages: 0,
  page_number: 0,
};

export const COLLECTION_PARAM_NAME: Dictionary = {
  page: 'page',
  filters: 'filters',
  sorts: 'sorts',
  profile: 'profile',
  timestamp: 'timestamp',
};

export const MULTISELECT_TYPE: Dictionary = {
  include: 'include',
  exclude: 'exclude',
};
