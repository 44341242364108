import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormErrors } from 'asap-team/asap-tools';
import { xorBy } from 'lodash-es';

// Types
import type { Checkout } from '@core/types';

// Interfaces
import { CheckoutWithPromoCode } from '@core/interfaces';

// Consts
import { PROMO_CODE_ERROR, applyPromoCodeErrors } from '@consts/consts';

// Services
import { BillingService } from '@core/services/billing/billing.service';

@UntilDestroy()
@Component({
  selector: 'apply-promo-code',
  templateUrl: './apply-promo-code.component.html',
  styleUrls: ['./apply-promo-code.component.scss'],
})
export class ApplyPromoCodeComponent implements OnInit {

  @Input() planName: string;

  @Input() checkout: Checkout;

  @Output()
  checkoutUpdate: EventEmitter<CheckoutWithPromoCode> = new EventEmitter<CheckoutWithPromoCode>();

  form: FormGroup;

  formErrors: FormErrors = applyPromoCodeErrors;

  isPromoApplied: boolean = false;

  message: string = '';

  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private billingService: BillingService,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({ promo_code: [''] });

    this.subscribes();
  }

  applyPromo(): void {
    const promoCode: any = this.form.get('promo_code').value;

    this.loading = true;

    // promocode endpoint for upgrade subscription
    if (this.planName) {
      this
        .billingService
        .checkout(this.planName, promoCode)
        .pipe(
          catchError((error: any) => {
            this.loading = false;
            this.formErrors.global = [PROMO_CODE_ERROR];

            return throwError(error);
          }),
          untilDestroyed(this),
        )
        .subscribe(
          (response: Checkout) => {
            this.loading = false;
            this.isPromoApplied = true;
            this.form.controls.promo_code.disable();
            this.message = xorBy(
              response.order_summary,
              this.checkout.order_summary,
              'title',
            )[0]?.title;

            const emittedEvent: CheckoutWithPromoCode = { checkout: response, promoCode };

            this.checkoutUpdate.emit(emittedEvent);
          },
        );

    // promocode endpoint for simple invoice
    } else {
      this
        .billingService
        .getCheckout(promoCode)
        .pipe(
          catchError((error: any) => {
            this.loading = false;
            this.formErrors.global = [PROMO_CODE_ERROR];

            return throwError(error);
          }),
          untilDestroyed(this),
        )
        .subscribe(
          (response: Checkout) => {
            this.loading = false;
            this.isPromoApplied = true;
            this.form.controls.promo_code.disable();
            this.message = xorBy(
              response.order_summary,
              this.checkout.order_summary,
              'title',
            )[0]?.title;

            const emittedEvent: CheckoutWithPromoCode = { checkout: response, promoCode };

            this.checkoutUpdate.emit(emittedEvent);
          },
        );
    }
  }

  removePromo(): void {
    this.loading = true;

    // promocode endpoint for upgrade subscription
    if (this.planName) {
      this
        .billingService
        .checkout(this.planName)
        .pipe(
          catchError((error: any) => {
            this.loading = false;
            this.formErrors.global = [PROMO_CODE_ERROR];

            return throwError(error);
          }),
          untilDestroyed(this),
        )
        .subscribe(
          (response: Checkout) => {
            this.loading = false;
            this.isPromoApplied = false;
            this.form.controls.promo_code.enable();
            this.message = '';

            this.checkoutUpdate.emit({ checkout: response });
          },
        );
    } else {
    // promocode endpoint for simple invoice
      this
        .billingService
        .getCheckout()
        .pipe(
          catchError((error: any) => {
            this.loading = false;
            this.formErrors.global = [PROMO_CODE_ERROR];

            return throwError(error);
          }),
          untilDestroyed(this),
        )
        .subscribe(
          (response: Checkout) => {
            this.loading = false;
            this.isPromoApplied = false;
            this.form.controls.promo_code.enable();
            this.message = '';

            this.checkoutUpdate.emit({ checkout: response });
          },
        );
    }
  }

  private subscribes(): void {
    this
      .form
      .valueChanges
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => { this.formErrors.global = []; });
  }

}
