import { Component, OnInit, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, tap } from 'rxjs/operators';

import type { CollectionCounters } from '@core/types';

// Services
import { FirebaseService } from '@core/vendors/firebase/firebase.service';

@UntilDestroy()
@Component({
  selector: 'tab-counters',
  templateUrl: './tab-counters.component.html',
  styleUrls: ['./tab-counters.component.scss'],
})
export class TabCountersComponent implements OnInit {

  @Input() collectionCounterName: string;

  @Input() isNewCounterDot: boolean = false;

  @Input() isRelativePath: boolean = false;

  isLoadEnd: boolean = false;

  newLeadsCounter: number;

  totalLeadsCounter: number;

  constructor(
    private firebaseService: FirebaseService,
  ) {}

  ngOnInit(): void {
    if (this.isRelativePath) {
      this
        .firebaseService
        .getCountersByPath(this.collectionCounterName)
        .pipe(
          untilDestroyed(this),
        )
        .subscribe(
          (counter: number) => {
            this.isLoadEnd = true;
            this.totalLeadsCounter = counter;
          },
        );

      return;
    }

    this
      .firebaseService
      .getCounters()
      .pipe(
        untilDestroyed(this),
        tap(() => { this.isLoadEnd = true; }),
        filter(Boolean),
      )
      .subscribe(
        (counters: CollectionCounters) => {
          this.newLeadsCounter = this.getCounterValue('new', counters);
          this.totalLeadsCounter = this.getCounterValue('total', counters);
        },
      );
  }

  private getCounterValue(type: 'new' | 'total', counters: CollectionCounters): number {
    if (this.collectionCounterName) {
      return counters[`${type}_${this.collectionCounterName}_counter`];
    }

    return 0;
  }

}
