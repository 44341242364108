import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError, required } from './conditions';

export const invoiceErrors: FormErrors = {
  description: [
    required,
    {
      name: 'minlength',
      text: 'Min length is 3',
      rules: ['touched', 'dirty'],
    },
    apiError,
  ],
  amount: [
    required,
    {
      name: 'min',
      text: 'Min value is $1',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'max',
      text: 'Must be less than $10,000',
      rules: ['dirty'],
    },
    apiError,
  ],
  global: [],
};
