export const EMAIL_PATTERN: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(\[?(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})\]?|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const OWNER_NAME: RegExp = /^[\p{L}][\p{L}\s',.-]*$/u;
export const FB_CAMP_ID: RegExp = /^[0-9]{17}$/;
export const FB_USERNAME_PATTERN: RegExp = /[^a-zA-Z0-9.]/g;
export const BATH_PATTERN: any = {
  0: { pattern: /[0-9]/ },
  5: { pattern: /[0|5]/ },
};
export const LENDER_MARGIN_PATTERN: RegExp = /^(\+|-)?[0-9]+([.,][0-9]{0,3})?$/;
export const GA_TRACKING_ID_PATTERN: RegExp = /[^\s.]+/;
export const FB_TRACKING_ID_PATTERN: RegExp = /^\d+$/;
export const GOOGLE_ADWORDS_PATTERN: RegExp = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
export const GOOGLE_TAG_MANAGER_PATTERN: RegExp = /[^\s.]+/;
export const LENDING_PAD_ID: RegExp = /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/;
export const NO_SPECIFIC_SYMBOLS_PATTERN: RegExp = /^[A-Za-z0-9]/;
export const JOB_TITLE_PATTERN: RegExp = /^[a-zA-Z0-9.-\s®,/&]+$/;
export const US_ZIP_PATTERN: RegExp = /^\d{5}(-\d{4})?$/;
export const PASSWORD_STRONG_VALIDATION_PATTERN: RegExp = /^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[!@#$%^&*? ])\S*$/;
export const URL_PATTERN: RegExp = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
export const DETECT_ID_IN_URL_PATTERN: (input: string[]) => RegExp = (segmentVariantsBeforeIdSegment: string[]) => new RegExp(`(/(?:${segmentVariantsBeforeIdSegment.join('|')})/(?:_/)?)[\\w.]+(/|$|\\?|_)`);
export const ONLY_POSITIVE_INTEGERS_PATTERN: RegExp = /^[1-9][0-9]*$/;
