import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  Input, OnChanges,
  OnInit,
  Output, SimpleChanges, ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { Params } from '@angular/router';
import { identity, isEmpty, pickBy } from 'lodash-es';

@UntilDestroy()
@Component({
  selector: 'iq-filters-container',
  templateUrl: './iq-filters-container.component.html',
  styleUrls: ['./iq-filters-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class IqFiltersContainerComponent implements OnInit, OnChanges {

  @Input() totalItems: number = 0;

  @Input() totalItemsLabel: string = 'items';

  @Input() totalTooltip: string = null;

  @Input() showColumnsPicker: boolean = false;

  @Input() showSearch: boolean = false;

  @Input() searchStateValue: string = '';

  @Input() minSearchLength: number = 2;

  @Input() customMediaBreakpoint: string = '(max-width: 1202px)';

  @Input() clearStateValue: Params;

  @Input() hideDesktopClearBtn: boolean = false;

  @Input() hideToggleButton: boolean = false;

  @Input() loading: boolean = false;

  @Output() onFiltersClear: EventEmitter<any> = new EventEmitter();

  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('searchElementRef', { static: true }) searchElementRef: ElementRef;

  mobileMode: boolean = false;

  showMobileFilters: boolean = false;

  searchControl: FormControl = new FormControl('');

  showSearchControl: boolean = false;

  isShowClearBtn: boolean = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { searchStateValue, clearStateValue } = changes;

    if (searchStateValue) {
      this.searchControl.patchValue(searchStateValue?.currentValue || '');
    }

    if (clearStateValue) {
      const filtersWithoutDefaultValue: Params = {
        ...clearStateValue.currentValue,
        date_range: clearStateValue.currentValue?.date_range === '12' ? null : clearStateValue.currentValue?.date_range,
      };

      this.isShowClearBtn = !isEmpty(pickBy(filtersWithoutDefaultValue, identity));
    }
  }

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        filter((value: string) => value?.length > this.minSearchLength || value?.length === 0),
        distinctUntilChanged(),
        debounceTime(1000),
        untilDestroyed(this),
      )
      .subscribe((value: string) => {
        this.onSearch.emit(value);
      });

    this.breakpointObserver
      .observe([this.customMediaBreakpoint])
      .pipe(untilDestroyed(this))
      .subscribe(({ matches }: BreakpointState) => {
        this.mobileMode = matches;
        this.cdr.markForCheck();
      });
  }

  get isShowSearchControl(): boolean {
    return this.searchControl.value || this.showSearchControl;
  }

  clearSearchInput(): void {
    this.searchControl.patchValue('');
  }

  search(event: { filterName: string; value: any }): void {
    this.onSearch.emit(event.value);
  }

  hideMobileFilters(emitClear: boolean = false): void {
    this.showMobileFilters = false;

    if (emitClear) {
      this.onFiltersClear.emit();
    }
  }

}
