import * as Sentry from '@sentry/browser';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar-observables';
import { Component, OnInit, Inject, HostBinding } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { take, map, filter, startWith } from 'rxjs/operators';
import {
  Event,
  Router,
  NavigationEnd,
  NavigationStart,
  NavigationError,
  NavigationCancel,
  RouterEvent,
} from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';
import {
  ClipboardResponseService,
  fadeIn,
  uID,
  // ModalService,
} from 'asap-team/asap-tools';

import type { Profile } from '@core/types';

// Consts
import { environment } from 'environments/environment';
import {
  ROUTE,
  // RELEASE_NOTE_MODAL_DISPLAYED,
} from '@consts/consts';

// Animations
import { routeTransitionAnimation } from '@core/animations/fade.animation';

// Components
// import { ReleaseNotesComponent } from '@commons/modals/release-notes/release-notes.component';

// Services
import { UserService } from '@core/services/user/user.service';
import { IntercomService } from '@core/vendors/intercom/intercom.service';
import { ProfileHashService } from '@core/helpers/profile-hash/profile-hash.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReleaseWardenService } from '@core/helpers/release-warden/release-warden.service';
import { AsaplyticsService } from '@core/helpers/tracking/asaplytics.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeIn(300), routeTransitionAnimation],
})
export class AppComponent implements OnInit {

  @HostBinding('@fadeIn') fadeIn: boolean = true;

  ROUTE: any = ROUTE;

  currentUrl: string = null;

  previousUrl: string = null;

  get isLoggedIn$(): Observable<boolean> {
    return this.userService.isLoggedIn();
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private loadingBar: SlimLoadingBarService,
    private userService: UserService,
    private intercomService: IntercomService,
    private clipboardResponseService: ClipboardResponseService,
    private asaplyticsService: AsaplyticsService,
    private profileHashService: ProfileHashService,
    // [TODO]: Temporary solution for MVP
    // private modalService: ModalService,
    private releaseWardenService: ReleaseWardenService,
  ) {}

  ngOnInit(): void {
    // init tracking (ga and gtag)
    this.asaplyticsService.init();

    this
      .router
      .events
      .subscribe((event: Event) => {
        this.checkRouterEvent(event);
      });

    // init profile hash tracking
    this
      .profileHashService
      .startProfileHashObservation()
      .subscribe();

    // Intercom
    if (environment.production) {
      combineLatest([
        this.userService.profile$,
        this.intercomService.intercom$,
      ])
        .pipe(
          take(2),
        )
        .subscribe(([profile, status]: [Profile, string]) => {
          if (!status) { return; }

          this.intercomService.start({
            email: profile.email,
            user_id: profile.id,
          });
        });

      this.intercomService.init();
    }

    // Sentry
    if (environment.sentryDSN) {
      Sentry.configureScope((scope: Sentry.Scope) => {
        scope.setUser({ id: uID('unauthorized_user') });
      });

      this
        .userService
        .profile$
        .subscribe(
          ({ id, email, username }: Profile) => {
            Sentry.configureScope((scope: Sentry.Scope) => {
              scope.setUser({
                id, email, username,
              });
            });
          },
        );
    }

    // init clipboard service
    this.clipboardResponseService.handleClipboardResponse();

    // init updates checker
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event: RouterEvent) => event instanceof NavigationStart),
        map((route: NavigationStart) => route.url),
        startWith(this.router.routerState.snapshot.url),
      )
      .subscribe(() => {
        this
          .releaseWardenService
          .enlist();
      });

    // show new release notes
    this.isLoggedIn$.subscribe((isLoggedIn: boolean) => {
      if (isLoggedIn) {
      // [TODO]: Temporary solution for MVP
      // const isReleaseNoteModalWasDisplayed: boolean = !!this.vaultService.get(RELEASE_NOTE_MODAL_DISPLAYED);

      // if (!isReleaseNoteModalWasDisplayed) {
      //   this.modalService
      //     .open(ReleaseNotesComponent, {}, { closeOnClickOutside: false })
      //     .pipe(untilDestroyed(this))
      //     .subscribe();
      // }
      }
    });
  }

  /**
  * This is used to intercept and show Loading bar based on the current state of Router navigation
  *
  * @param {Event} event
  */

  private checkRouterEvent(event: Event): void {
    if (event instanceof NavigationStart) {
      this.loadingBar.start();
    }

    if (event instanceof NavigationEnd) {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.loadingBar.complete();
      this.window.scrollTo(0, 0);
      this.asaplyticsService.trackPageForUrl(event.urlAfterRedirects);
    }

    if (event instanceof NavigationCancel) {
      this.loadingBar.complete();
    }

    if (event instanceof NavigationError) {
      this.loadingBar.complete();
    }
  }

}
