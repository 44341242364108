import jwtDecode from 'jwt-decode';
import { FormGroup } from '@angular/forms';
import {
  pick,
  pickBy,
  assign,
  identity,
  isArray,
} from 'lodash-es';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  map,
  tap,
  switchMap,
  filter,
  shareReplay,
  take,
} from 'rxjs/operators';
import {
  ModalService,
  uID,
  BaseHttpService,
  VaultService,
  percent,
  Dictionary,
  isUrlIncludes,
} from 'asap-team/asap-tools';

import * as RequestParams from '@core/types/request';
import type {
  CheckboxItem,
  Profile,
  UserRole,
  AdminProfile,
  EmailTemplate,
  AccountDetails,
  AuthToken,
  Avatar,
  FTLStep,
  FTLPartner,
  BillingPlan,
  IUserWorkingHours,
  ReferralUser,
  ProfileResponse,
} from '@core/types';

// Consts
import {
  ROUTE,
  PROFILE,
  JWT_TOKEN,
  USER_ROLE,
  PROFILE_HASH,
  SUBSCRIPTION_STATUS,
  BILLING_PLAN,
} from '@consts/consts';
import { FTLStepName } from '@modules/auth/sign-up/ftl/state/ftl.model';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { StateResetAll } from 'ngxs-reset-plugin';

@Injectable({ providedIn: 'root' })
export class UserService {

  private readonly profile: BehaviorSubject<Profile> = new BehaviorSubject<Profile>(this.vaultService.get(PROFILE) || null);

  private readonly logoutAction: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  private readonly token: BehaviorSubject<string> = new BehaviorSubject(this.vaultService.get(JWT_TOKEN) || null);

  logoutAction$: Observable<boolean> = this.logoutAction.asObservable();

  profile$: Observable<Profile> = this
    .profile
    .asObservable()
    .pipe(
      filter<Profile>(Boolean),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );

  token$: Observable<AuthToken> = this
    .token
    .asObservable()
    .pipe(
      tap((token: string) => {
        if (!token) {
          this.vaultService.remove(JWT_TOKEN);

          return;
        }

        this.vaultService.set(JWT_TOKEN, token);
      }),
      map((token: string) => {
        const expired: boolean = this.isTokenExpired(token);

        return {
          token,
          expired,
          authorized: !!token && !expired,
        };
      }),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );

  get isRegistrationCompleted(): boolean {
    const profile: Profile = this.profile.getValue();

    return this.isSuperAdmin(profile?.role) || !!profile?.registration_completed;
  }

  constructor(
    private http: BaseHttpService,
    private modalService: ModalService,
    private vaultService: VaultService,
    private store: Store,
    private router: Router,
  ) { }

  syncGetProfile(): Profile {
    return this.profile.getValue();
  }

  setToken(token: string): void {
    this.token.next(token);
  }

  login(form: { email: string; password: string }): Observable<Profile> {
    return this
      .http
      .post('v2/auth/sign_in', { api_v2_user: form })
      .pipe(
        map(this.toProfile.bind(this)),
      );
  }

  loginUnderUserAccount(userID: string): Observable<Profile> {
    return this
      .http
      .post(`v2/auth/${userID}/login`, {})
      .pipe(
        map(this.toProfile.bind(this)),
      );
  }

  loginWithToken(auth_token: string): Observable<Profile> {
    return this
      .http
      .post('v2/auth/login_with_auth_token', { auth_token })
      .pipe(
        map(this.toProfile.bind(this)),
      );
  }

  logout(isMakeRequest: boolean): Observable<any> | null {
    const remove = (): void => {
      this.vaultService.remove(JWT_TOKEN);
      this.vaultService.remove(PROFILE);
      this.vaultService.remove(PROFILE_HASH);
      this.token.next(null);
      this.resetStore();
      this.logoutAction.next(true);
      this.profile.next(null);
    };

    this.modalService.removeAll();

    if (isMakeRequest) {
      return this
        .http
        .delete('v2/auth/sign_out')
        .pipe(
          tap(() => remove()),
        );
    }

    remove();

    return null;
  }

  private resetStore(): void {
    const profile: Profile = this.profile.getValue();

    if (profile && !this.isSuperAdmin(profile?.role)) {
      this.store.dispatch(new StateResetAll());
    }
  }

  getRawProfile(): Observable<Profile> {
    return this
      .http
      .get('v2/profile')
      .pipe(
        map(({ data }: ProfileResponse) => {
          this.emitProfileUpdate(data);

          return data;
        }),
      );
  }

  getAdminProfile(): Observable<AdminProfile> {
    return this
      .http
      .get('v2/admin/profile')
      .pipe(
        map(({ data }: { data: AdminProfile }) => {
          const currentProfile: Profile = this.profile.getValue();
          const profile: Profile = assign(currentProfile, data);

          this.emitProfileUpdate(profile);

          return profile;
        }),
      );
  }

  updateProfile(updatedProfile: Profile, isMakeRequest: boolean = true): Observable<Profile> {
    const currentProfile: Profile = this.profile.getValue();
    const profile: any = assign(currentProfile, updatedProfile);

    const partial: RequestParams.updateProfile = pick(profile, [
      'name',
      'email',
      'phone',
      'job_title',
      'fb_messenger',
      'company_name',
      'license_number',
      'disclaimer_text',
      'disclaimer_logo',
      'company_license_number',
      'license_states',
      'mls_number',
      'lending_pad_company_id',
      'lending_pad_campaign_id',
      'time_zone',
      'facebook_url',
      'instagram_url',
      'linkedin_url',
      'personal_website_url',
      'realtor_url',
      'youtube_url',
      'zillow_url',
    ]);

    // Fix update if license_states is array
    if (isArray(partial.license_states)) {
      partial.license_states = partial.license_states.join(',');
    }

    if (isMakeRequest) {
      return this
        .http
        .patch('v2/profile', partial)
        .pipe(
          map(({ data }: { data: Profile }) => {
            this.emitProfileUpdate(data);

            return data;
          }),
        );
    }

    this.emitProfileUpdate(profile);

    return of(profile);
  }

  emitProfileUpdate(profile: Profile): void {
    this.vaultService.set(PROFILE, profile);
    this.profile.next(profile);
  }

  updateAdminProfile({ name, email }: { name: string; email: string }): Observable<AdminProfile> {
    return this
      .http
      .patch('v2/admin/profile', { name, email })
      .pipe(
        map((adminProfile: AdminProfile) => {
          const currentProfile: Profile = this.profile.getValue();
          const profile: Profile = assign(currentProfile, adminProfile);

          this.emitProfileUpdate(profile);

          return adminProfile;
        }),
      );
  }

  getUserRole(): UserRole {
    const user: Profile = this.profile.getValue();

    if (user) {
      return user.role;
    }

    return null;
  }

  getWorkingHours(): IUserWorkingHours {
    const user: Profile = this.profile.getValue();

    if (user && user.work_hours_end && user.work_hours_start) {
      return {
        work_hours_end: user.work_hours_end,
        work_hours_start: user.work_hours_start,
      };
    }

    return null;
  }

  getTimeZone(): string {
    const user: Profile = this.profile.getValue();

    return user?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  isUserRole(roles: string | string[]): boolean {
    const role: string = this.getUserRole();

    if (typeof roles === 'string') {
      return roles === role;
    }

    return !!~roles.indexOf(role);
  }

  isUserLimitedByPaywall(): boolean {
    const { subscription_status } = this.syncGetProfile() || null;

    return (
      subscription_status === SUBSCRIPTION_STATUS.PAUSED
      || subscription_status === SUBSCRIPTION_STATUS.MONITORING_PROGRAM
    );
  }

  getUserBillingPlan(): BillingPlan {
    const user: Profile = this.profile.getValue();

    if (user) {
      return user.plan_name as BillingPlan;
    }

    return null;
  }

  isUserPlan(plans: string | string[]): boolean {
    const plan: string = this.getUserBillingPlan();

    if (typeof plans === 'string') {
      return plan === plans;
    }

    return !!~plans.indexOf(plan);
  }

  getTrialAlertEnabledStatus(): boolean {
    const profile: Profile = this.profile.getValue();

    if (profile.plan_name === BILLING_PLAN.TRIAL && profile.remaining_limits <= 5) {
      return true;
    }

    return false;
  }

  registration(form: RequestParams.reservationData): Observable<any> {
    return this.http.post('v2/registrations', form);
  }

  registerUserFromViral(id: string, params: any): Observable<{ type: string; id: string }> {
    return this.http.get(`v1/history/users/${id}`, { params });
  }

  resetPassword(form: RequestParams.resetPassword): Observable<any> {
    return this.http.post('v2/auth/password', form);
  }

  validatePassword(form: FormGroup): Observable<void> {
    return this.http.post('v2/profile/check_password', { password: form.value.current_password });
  }

  updatePassword(form: RequestParams.updatePassword): Observable<{}> {
    return this.http.put('v2/auth/password', form);
  }

  updateSettings(form: FormGroup): Observable<Profile> {
    const params: Partial<any> = pickBy(form.value, identity);

    return this
      .http
      .patch('v2/settings', params, { observe: 'response' });
  }

  confirmPhone(code: string): Observable<void> {
    return this.http.patch('v2/profile/phones/confirm_code', { code }, { observe: 'response' });
  }

  requestConfirmEmail(): Observable<void> {
    const user: Profile = this.profile.getValue();

    return this.http.post('v2/emails', { user_id: user.id });
  }

  confirmEmail(email: string, token: string): Observable<{ id: string; type: string }> {
    return this.http.post('v2/emails/confirm', { email, token });
  }

  sendPhoneConfirmation(): Observable<void> {
    return this.http.patch('v2/profile/phones/send_code', {}, { observe: 'response' });
  }

  getInviteLink(): Observable<{ referral_link: string }> {
    return this.http.get('v2/profile/referral_link');
  }

  acceptInvite(token: string, email: string): Observable<any> {
    return this.http.patch('v2/invites/accept', { token, email });
  }

  declineInvite(token: string, email: string): Observable<any> {
    return this.http.patch('v2/invites/decline', { token, email });
  }

  resendInvite(): Observable<any> {
    return this.http.post('v2/invitations', {});
  }

  completeAccount({ token, email }: any): Observable<{ id: string; type: string; profile: Profile; hash: string }> {
    return this.http.patch('v2/registrations/first_time_login', { token, email });
  }

  profileRegistration(updatedUser: Profile): Observable<Profile> {
    const currentUser: Profile = this.profile.getValue();
    const result: Profile = assign(currentUser, updatedUser);

    return this.http.patch('v2/registrations/profile', result).pipe(
      map(({ data }: { data: Profile }) => {
        this.emitProfileUpdate(data);

        return data;
      }),
    );
  }

  enterpriseRegistration(formData: FormData, code: string): Observable<Profile> {
    return this
      .http
      .post(`v2/companies/${code}/registrations`, formData)
      .pipe(
        map(this.toProfile.bind(this)),
      );
  }

  titleUserRegistration(formData: FormData): Observable<Profile> {
    return this
      .http
      .post('v2/registrations/title_user', formData)
      .pipe(
        map(this.toProfile.bind(this)),
      );
  }

  getFtlSteps(): Observable<{ id: string; steps: FTLStepName[]; type: string }> {
    return this.http.get('v2/ftl/steps');
  }

  getFtlStep(step_code: string): Observable<FTLStep> {
    return this
      .http
      .get(`v2/ftl/steps/${step_code}`)
      .pipe(
        map((response: { data: FTLStep; hash: string }) => response.data),
      );
  }

  ftlProgress(steps: string[]): Observable<string> {
    if (!steps?.length) { return of('0%'); }

    return this
      .profile$
      .pipe(
        map((profile: Profile) => `${percent(steps.indexOf(profile.ftl_step) + 1, steps.length, 1)}%`),
      );
  }

  skipFtlStep(step_code: FTLStepName): Observable<Profile> {
    return this
      .http
      .delete(`v2/ftl/steps/${step_code}/skip`)
      .pipe(
        map((response: { data: { profile: Profile }; hash: string }) => response?.data),
        filter(Boolean),
        map(this.toProfile.bind(this)),
      );
  }

  backFtlStep(step_code: string): Observable<Profile> {
    return this
      .http
      .patch(`v2/ftl/steps/${step_code}/back`, null)
      .pipe(
        map((response: { data: { profile: Profile }; hash: string }) => response?.data),
        filter(Boolean),
        map(this.toProfile.bind(this)),
      );
  }

  skipFtlStepWithoutUpdate(step_code: string): Observable<Profile> {
    return this
      .http
      .delete(`v2/ftl/steps/${step_code}/skip`)
      .pipe(
        map((response: { data: { profile: Profile }; hash: string }) => response?.data?.profile),
      );
  }

  submitFtlStep(step_code: string, payload?: Dictionary): Observable<{ profile: Profile; report_url: string }> {
    return this
      .http
      .post(`v2/ftl/steps/${step_code}/submit`, payload || null)
      .pipe(
        map((response: { data: { profile: Profile }; hash: string }) => response?.data),
        filter(Boolean),
        tap(this.toProfile.bind(this)),
        map(({ profile, report_url }: { profile: Profile; report_url: string }) => {
          return { profile, report_url };
        }),
      );
  }

  submitFtlStepWithoutUpdate(step_code: string, payload?: Dictionary): Observable<Profile> {
    return this
      .http
      .post(`v2/ftl/steps/${step_code}/submit`, payload || null)
      .pipe(
        map((response: { data: { profile: Profile }; hash: string }) => response?.data?.profile),
      );
  }

  searchForAgent(search: string): Observable<FTLPartner[]> {
    return this
      .http
      .get('v2/ftl/helpers/search_for_agent', { params: { search } });
  }

  searchForLender(search: string): Observable<FTLPartner[]> {
    return this
      .http
      .get('v2/ftl/helpers/search_for_lender', { params: { search } });
  }

  uploadAvatar(image: Blob, name: string): Observable<Avatar> {
    const formData: FormData = new FormData();

    formData.append('avatar', image, name);

    return this
      .profile$
      .pipe(
        take(1),
        switchMap(
          (currentUser: Profile) => this
            .http
            .patch('v2/profile/avatar', formData)
            .pipe(
              map<{ data: Profile }, Avatar>(({ data }: { data: Profile }) => {
                if (currentUser.username === data.username) {
                  this.emitProfileUpdate(data);
                }

                return data.avatar;
              }),
            ),
        ),
      );
  }

  uploadCustomerAvatar(image: Blob, user_id: string, name: string): Observable<Avatar> {
    const formData: FormData = new FormData();

    formData.append('avatar', image, name);

    return this
      .http
      .patch(`v2/admin/users/${user_id}/avatar`, formData)
      .pipe(
        map((data: AccountDetails) => data.avatar),
      );
  }

  getWelcomeEmailTemplate(): Observable<EmailTemplate> {
    return this.http.get('v2/email_templates/digest_welcome');
  }

  updateWelcomeEmailTemplate(payload: { welcome_email_subject: string; welcome_email_template: string }): Observable<any> {
    return this
      .http
      .patch('v2/email_templates/digest_welcome', payload);
  }

  getAvailablePropertiesStates(): Observable<CheckboxItem[]> {
    return this
      .http
      .get('v2/properties/states')
      .pipe(
        map(({ states }: { states: CheckboxItem[] }) => states.map((state: CheckboxItem) => {
          return {
            ...state,
            uid: uID(state.code),
          };
        })),
      );
  }

  grantAccess(): Observable<any> {
    return this
      .http
      .post('v2/profile/grant_access', null);
  }

  getLogoRoute(): string {
    const role: UserRole = this.syncGetProfile()?.role;

    switch (role) {
      case USER_ROLE.owner: {
        return ROUTE.alias.USERS_HOME_IQ;
      }
      case USER_ROLE.lender: {
        return ROUTE.alias.DASHBOARD_PRODUCTION;
      }
      case USER_ROLE.sales: {
        return ROUTE.alias.USERS_HOME_IQ_TRIAL;
      }
      case USER_ROLE.customer_success: {
        return ROUTE.alias.USERS_HOME_IQ;
      }
      case USER_ROLE.marketing: {
        return ROUTE.alias.BANNERS;
      }
      default: {
        return '';
      }
    }
  }

  getUserId(): string {
    const profile: Profile = this.profile.getValue();

    return profile.id;
  }

  getReferrals(): Observable<ReferralUser[]> {
    return this
      .http
      .get('v2/referrals');
  }

  sendReferrals(referrals: { name: string; email: string; phone: string }[]): Observable<any> {
    const payload: { referrals: { name: string; email: string; phone: string }[] } = { referrals };

    return this.http.post('v2/referrals', payload);
  }

  isLoggedIn(): Observable<boolean> {
    const disallowed: string[] = [
      ROUTE.name.SIGN_IN,
      ROUTE.name.SIGN_UP,
      ROUTE.name.CONFIRM_EMAIL,
      ROUTE.name.EXPIRED_LINK,
      ROUTE.name.EMAIL_SUBSCRIPTION,
    ];

    return this.token$
      .pipe(
        map(({ authorized }: AuthToken) => authorized && !isUrlIncludes(disallowed, this.router)),
      );
  }

  private toProfile(response: { id: string; type: string; profile: Profile }): Profile {
    this.logoutAction.next(false);

    if (response?.id?.includes('Bearer')) {
      this.setToken(response.id);
    }

    this.emitProfileUpdate(response.profile);

    return response.profile;
  }

  private isTokenExpired(token: string): boolean {
    let decoded: { user_id: number; exp: number };

    if (!token) {
      return true;
    }

    try {
      decoded = jwtDecode(token);
    } catch (e) {
      return true;
    }

    if (decoded.exp === undefined) {
      return true;
    }

    const expiration: number = new Date(0).setUTCSeconds(decoded.exp);

    return !expiration || expiration <= Date.now();
  }

  public isSuperAdmin(role: string): boolean {
    return [
      USER_ROLE.owner,
      USER_ROLE.sales,
      USER_ROLE.marketing,
      USER_ROLE.customer_success,
    ]
      .some((userRole: UserRole) => userRole === role);
  }

}
