import type { Dictionary } from 'asap-team/asap-tools';

export const PERMITTED_ACTION: Dictionary = {
  // region Global / Misc
  ADMIN_PROFILE_MANAGE: 'admin_profile_manage',
  CREATE_FREE: 'create_free',
  CREATE_PAID: 'create_paid',
  USER_PARTNERSHIPS: 'user_partnerships',
  CREATE_DRAFT: 'create_draft',
  CREATE_DRAFT_NEED_COMPLETE_PROFILE: 'create_draft_need_complete_profile',
  DATABASES_UPLOAD: 'databases_upload',
  DATABASES_UPLOAD_STATE_COMPLETE_ACCOUNT: 'databases_upload_state_complete_account',
  PROFILE_MANAGE: 'profile_manage',
  BILLING_UPGRADE: 'billing_upgrade',
  PAYMENT_PROBLEM: 'payment_problem',
  INVITE_PARTNER: 'invite_partner',
  HOMEOWNERS_LEADS_EXPORT: 'homeowners_leads_export',
  REFERRAL_MANAGE: 'referral_manage',
  REFERRAL_VIEW: 'referral_view',
  REFERRAL_EXPIRED: 'referral_manage_expired',
  HOME_SHOPPER: 'home_shopper',
  HOME_SHOPPER_STATS_VIEW: 'active_shopper_stats_view',
  // endregion

  // region Main menu (Admins/Staff)
  ACCOUNTS_VIEW: 'accounts_view',
  ACCOUNTS_MANAGE: 'accounts_manage',
  TRIALS_VIEW: 'trials_view',
  TRIALS_MANAGE: 'trials_manage',
  ARCHIVED_VIEW: 'archived_view',
  ARCHIVED_MANAGE: 'archived_manage',
  STAFF_VIEW: 'staff_view',
  STAFF_MANAGE: 'staff_manage',
  VIRALS_VIEW: 'virals_view',
  VIRALS_MANAGE: 'virals_manage',
  PROMO_CODES_VIEW: 'promo_codes_view',
  PROMO_CODES_MANAGE: 'promo_codes_manage',
  DATABASES_VIEW: 'databases_view',
  DATABASES_MANAGE: 'databases_manage',
  DATABASES_VERIFY: 'databases_verify',
  BANNERS_VIEW: 'banners_view',
  BANNERS_MANAGE: 'banners_manage',
  ENTERPRISES_VIEW: 'enterprises_view',
  ENTERPRISES_MANAGE: 'enterprises_manage',
  STAFF_PROFILE_MANAGE: 'staff_profile_manage',
  // endregion

  // region Main menu (Agent/Lender)
  DASHBOARD_VIEW: 'dashboard_view',
  HOMEOWNERS_VIEW: 'homeowners_view',
  HOMEOWNERS_MANAGE: 'homeowners_manage',
  HOMEOWNERS_REPORTS_VIEW: 'homeowners_reports_view',
  REFI_MANAGE: 'refi_manage',
  HOMEOWNERS_LEADS_VIEW: 'homeowners_leads_view',
  HOMEOWNER_LEADS_LOCK_SUBSCRIPTION_RENEW: 'homeowner_leads_lock_subscription_renew',
  HOMEOWNER_LEADS_STATE_PROMO: 'homeowner_leads_state_promo',
  HOMEOWNER_BANNERS_VIEW: 'homeowner_banners_view',
  HOMEOWNER_BANNERS_LOCK_SUBSCRIPTION_RENEW: 'homeowner_banners_lock_subscription_renew',
  HOMEOWNER_BANNERS_STATE_PROMO: 'homeowner_banners_state_promo',
  BUYERS_VIEW: 'buyers_view',
  BUYER_LEADS_VIEW: 'buyer_leads_view',
  BUYER_LEADS_LOCK_SUBSCRIPTION_RENEW: 'buyer_leads_lock_subscription_renew',
  BUYER_LEADS_LOCK_BY_LENDER_UPGRADE: 'buyer_leads_lock_by_lender_upgrade',
  BUYER_LEADS_STATE_PROMO: 'buyer_leads_state_promo',
  BUYER_BANNERS_VIEW: 'buyer_banners_view',
  BUYER_BANNERS_LOCK_SUBSCRIPTION_RENEW: 'buyer_banners_lock_subscription_renew',
  BUYER_BANNERS_LOCK_BY_LENDER_UPGRADE: 'buyer_banners_lock_by_lender_upgrade',
  BUYER_BANNERS_STATE_PROMO: 'buyer_banners_state_promo',
  PARTNERS_VIEW: 'partners_view',
  BILLING_MANAGE: 'billing_manage',
  HOME_WEALTH_LANDING_MANAGE: 'home_wealth_landing_manage',
  HOMEOWNERS_LANDING_MANAGE: 'homeowners_landing_manage',
  HOMEOWNERS_FEES_MANAGE: 'homeowners_fees_manage',
  SELLER_FEES_PERSONAL_MANAGE: 'seller_fees_personal_manage',
  HOMEOWNERS_WHITE_LABEL_MANAGE: 'homeowners_white_label_manage',
  HOMEOWNERS_EMAIL_MANAGE: 'homeowners_email_manage',
  BUYERS_LANDING_MANAGE: 'buyers_landing_manage',
  BUYERS_FEES_MANAGE: 'buyers_fees_manage',
  BUYERS_PROGRAMS: 'buyers_programs',
  INTEGRATIONS_MANAGE: 'integrations_manage',
  KUDOS_VIEW: 'kudos_view',
  INTELLIGENCE_CONNECT: 'intelligence_connect',
  DASHBOARD_PRODUCTION_VIEW: 'dashboard_production_view',
  // endregion

  // region Others
  ENTERPRISE_RESTRICTIONS: 'enterprise_restrictions',
  AFFORDABLE_CALCULATOR_MANAGE: 'affordable_calculator_manage',
  // endregion

  // region Leads
  ACTIVATE: 'activate',
  ACTIVATE_NEED_COMPLETE_ACCOUNT: 'activate_need_complete_account',
  ARCHIVE: 'archive',
  DEACTIVATE: 'deactivate',
  DELETE: 'delete',
  DOWNLOAD_QUOTE: 'download_quote',
  DOWNLOAD_REFINANCE_REPORT: 'download_refinance_report',
  DOWNLOAD_SELLING_REPORT: 'download_selling_report',
  EMAIL: 'email',
  IMPORT: 'import',
  RECALCULATE_DATA: 'recalculate_property_data',
  RECALCULATE_FEES: 'recalculate_fees',
  SEND_QUOTE_VIA_EMAIL: 'send_quote_via_email',
  SEND_QUOTE_VIA_SMS: 'send_quote_via_sms',
  SEND_REPORT_VIA_EMAIL: 'send_report_via_email',
  SEND_REPORT_VIA_SMS: 'send_report_via_sms',
  SMS: 'sms',
  EDIT_LEAD_DATA: 'edit_lead_data',
  EMAIL_NET_SHEET: 'email_net_sheet',
  SMS_NET_SHEET: 'sms_net_sheet',
  EMAIL_REPORT: 'email_report',
  SMS_REPORT: 'sms_report',
  ACTIVATE_EMAIL: 'activate_email',
  DEACTIVATE_EMAIL: 'deactivate_email',
  ACTIVATE_SMS: 'activate_sms',
  DEACTIVATE_SMS: 'deactivate_sms',
  ACTIVATE_NOW: 'activate_now',
  // endregion

  // region Accounts
  ACCOUNT_BLOCK: 'block',
  ACCOUNT_UNBLOCK: 'unblock',
  IMPORT_LEADS: 'import_leads',
  LOGIN: 'login',
  REQUEST_ACCESS: 'request_access',
  RESEND_INVITE: 'resend_invite',
  EXPORT_HOMEOWNERS: 'export_homeowners',
  EXPORT_BUYERS: 'export_buyers',
  EXPORT_PENDING: 'export_pending',
  REMOVE_DATABASE: 'remove_database',
  REMOVE_DATABASE_FORM_IMPORT: 'remove_database_from_import',
  ASSIGN_STAFF: 'assign_staff',
  // endregion

  // region Members
  ASSIGN_MEMBERS: 'assign_members',
  // endregion

  // region Uploads/Imports collection
  CLAIM: 'claim',
  COMPLETE: 'complete',
  // endregion

  // region Invoices
  INVOICE_DELETE: 'delete',
  INVOICE_RESEND: 'resend',
  INVOICE_UPDATE: 'update',
  INVOICE_FINALIZE: 'finalize',
  // endregion

  // region Cancel subscription
  SUB_CANCEL: 'cancel',
  SUB_ENROLL: 'enroll',
  SUB_ENROLL_BACK: 'enroll_back',
  SUB_PAUSE: 'pause',
  SUB_RESUME: 'resume',
  SUB_DISCOUNT: 'apply_discount',
  // endregion

  // region Draft lead
  LEAD_DRAFT_PHOTO_UPDATE: 'lead_draft_photo_update',
  LEAD_DRAFT_AVM_UPDATE: 'lead_draft_avm_update',
  LEAD_DRAFT_LOAN_UPDATE: 'lead_draft_loan_update',
  LEAD_DRAFT_DETAILS_UPDATE: 'lead_draft_details_update',
  LEAD_DRAFT_FEES_UPDATE: 'lead_draft_fees_update',
  LEAD_DRAFT_MEMBERS_UPDATE: 'lead_draft_members_update',
  LEAD_DRAFT_CONTACTS_UPDATE: 'lead_draft_contacts_update',
  LEAD_DRAFT_SENDING_UPDATE: 'lead_draft_sending_update',
  LEAD_DRAFT_AVM_RELOAD: 'lead_draft_avm_reload',
  LEAD_DRAFT_LOAN_RELOAD: 'lead_draft_loan_reload',
  LEAD_DRAFT_DETAILS_RELOAD: 'lead_draft_details_reload',
  LEAD_DRAFT_FEES_RELOAD: 'lead_draft_fees_reload',
  // endregion

  // region Partnership
  INTELLIGENCE_COLLEAGUES_VIEW: 'intelligence_colleagues_view',
  INTELLIGENCE_SEARCH_VIEW: 'intelligence_search_view',
  INTELLIGENCE_LOAN_OFFICERS_SEARCH_VIEW: 'intelligence_loan_officers_search_view',
  INTELLIGENCE_COLLEAGUES_STATE_PROMO: 'intelligence_colleagues_state_promo',
  INTELLIGENCE_SEARCH_STATE_PROMO: 'intelligence_search_state_promo',
  INTELLIGENCE_LOAN_OFFICERS_SEARCH_STATE_PROMO: 'intelligence_loan_officers_search_state_promo',
  INVITE_AGENT: 'invite_agent',
  INVITATION_LINK: 'invitation_link',
  INVITE_RESEND: 'resend',
  INVITE_CANCEL: 'cancel',
  INVITE_ACCEPT: 'accept',
  INVITE_DECLINE: 'decline',
  INVITE_VIEW: 'view',
  // endregion

  // region Training section
  TRAINING_VIEW: 'training_view',
  TRAINING_VIDEO_MANAGE: 'training_video_manage',
  REALTOR_CONNECTION_KIT_MANAGE: 'realtor_connection_kit_manage',
  MARKETING_FLYER_MANAGE: 'marketing_flyer_manage',
  // endregion

  // region Transaction reports section
  REPORT_ACTIVATE: 'report_activate',
  REPORT_VIEW: 'report_view',
  REPORT_EDIT: 'report_edit',
  // endregion
};
