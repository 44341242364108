import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  EventEmitter,
  Component,
  OnInit,
  Input,
  Output,
} from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dictionary, FormErrors } from 'asap-team/asap-tools';
import { addressValidator } from '@core/validators/address/address.validator';

// Consts
import { googleAddressErrors } from '@consts/form-errors';

@UntilDestroy()
@Component({
  selector: 'google-address-form',
  templateUrl: './google-address-form.component.html',
})
export class GoogleAddressFormComponent implements OnInit {

  @Input() initialState: Dictionary = null;

  @Input() placeholder: string;

  @Output()
  formComplete: EventEmitter<Dictionary> = new EventEmitter<Dictionary>();

  form: FormGroup;

  formErrors: FormErrors = googleAddressErrors;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
    this.setInitialState();
    this.subscribeToFormChange();
  }

  private initForm(): void {
    this.form = this.fb.group({
      address: [
        {
          full_address: '',
          unit: '',
          city: '',
          state: '',
          street: '',
          zip: '',
          country: '',
          lat: '',
          lng: '',
        },
        [Validators.required, addressValidator],
      ],
    });
  }

  private setInitialState(): void {
    if (this.initialState) {
      this.form.patchValue({ address: this.initialState });
    }
  }

  private subscribeToFormChange(): void {
    this.form.statusChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(
          (prev: string, curr: string) => prev === 'INVALID' && curr === 'INVALID',
        ),
      )
      .subscribe(() => {
        const newValue: Dictionary = this.form.valid ? this.form.value : null;

        this.formComplete.emit(newValue);
      });
  }

}
