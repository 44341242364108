import {
  Action, Selector, State, StateContext, Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { SingleAnalyticEvent } from '@consts/analytics-events.interface';

// Const
import { ANALYTICS_EVENTS } from '@consts/analytics';

// Utils
import { percent } from 'asap-team/asap-tools';

// Services
import { UserService } from '@core/services/user/user.service';

// State
import { FTLStep, Profile } from '@core/types';
import { AsaplyticsService } from '@core/helpers/tracking/asaplytics.service';
import { FTLStateModel, FTLStepName } from './ftl.model';
import { FTLActions } from './ftl.actions';

@State<FTLStateModel>({
  name: 'FtlState',
  defaults: new FTLStateModel(),
})
@Injectable()
export class FtlState {

  @Selector() static steps(state: FTLStateModel): FTLStepName[] {
    return state.steps;
  }

  @Selector() static currentStep(state: FTLStateModel): FTLStepName {
    return state.currentStep;
  }

  @Selector() static ftlProgress(state: FTLStateModel): number {
    return percent(state.steps.indexOf(state.currentStep) + 1, state.steps.length, 1);
  }

  @Selector() static ftlStepData(state: FTLStateModel): FTLStep {
    return state.ftlStepData;
  }

  constructor(
    private store: Store,
    private userService: UserService,
    private asaplyticsService: AsaplyticsService,
  ) {}

  @Action(FTLActions.GetFtlStepsList)
  async getFtlStepsList(ctx: StateContext<FTLStateModel>): Promise<void> {
    ctx.setState(new FTLStateModel());

    const steps: FTLStepName[] = (await this.userService.getFtlSteps().toPromise())?.steps || [];
    const currentStep: FTLStepName = this.userService.syncGetProfile()?.ftl_step as FTLStepName;

    ctx.patchState({ steps, currentStep });
  }

  @Action(FTLActions.GetStepDataAction)
  getStepDataAction(ctx: StateContext<FTLStateModel>, action: FTLActions.GetStepDataAction): any {
    return this.userService.getFtlStep(action.step_code).pipe(
      tap((response: FTLStep) => {
        ctx.patchState({ ftlStepData: response });

        // Emit analytics events
        const { ftl_step, role } = response.profile;
        const analyticEvent: SingleAnalyticEvent = ANALYTICS_EVENTS.FTL[role].ftl_2024[ftl_step];

        this.asaplyticsService.sendEvent(analyticEvent);
      }),
    );
  }

  @Action(FTLActions.SkipFtlStepAction)
  skipStepAction(ctx: StateContext<FTLStateModel>, action: FTLActions.SkipFtlStepAction): any {
    return this.userService.skipFtlStep(action.step_code).pipe(
      tap((response: Profile) => {
        ctx.patchState({ currentStep: response.ftl_step as FTLStepName });
      }),
    );
  }

  @Action(FTLActions.SubmitFtlStepAction)
  submitFtlStepAction(ctx: StateContext<FTLStateModel>, action: FTLActions.SubmitFtlStepAction): any {
    return this.userService.submitFtlStep(action.step_code, action.payload).pipe(
      tap((response: { profile: Profile }) => {
        ctx.patchState({ currentStep: response.profile.ftl_step as FTLStepName });
      }),
    );
  }

}
