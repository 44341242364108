import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError } from './conditions';

export const applyPromoCodeErrors: FormErrors = {
  promo_code: [
    apiError,
  ],
  global: [],
};
