import {
  Component,
  OnInit,
  Input,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Dictionary, FormErrors } from 'asap-team/asap-tools';

import type { UserRole } from '@core/types';

// Consts
import {
  USER_ROLE,
  INVITE_LENDER_MESSAGE,
  INVITE_AGENT_MESSAGE,
  USER_LABEL,
  EMAIL_PATTERN,
  invitePartnerErrors,
  SHORTCODES_LIST,
} from '@consts/consts';

// Validators
import { phoneValidator } from '@core/validators/phone/phone.validator';

@UntilDestroy()
@Component({
  selector: 'invite-partner-form',
  templateUrl: './invite-partner-form.component.html',
  styleUrls: ['./invite-partner-form.component.scss'],
})
export class InvitePartnerFormComponent implements OnInit {

  @Input() query: string;

  @Input() userRole: string;

  USER_ROLE: Dictionary<UserRole> = USER_ROLE;

  USER_LABEL: Dictionary<string> = USER_LABEL;

  form: FormGroup;

  formErrors: FormErrors = invitePartnerErrors;

  shortcodesList: string[] = SHORTCODES_LIST;

  constructor(
    private fb: FormBuilder,
  ) {}

  get emailControlPlaceholder(): string {
    if (this.userRole === USER_ROLE.agent) {
      return 'Loan Officer email address';
    }

    return 'Agent email address';
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [this.query, [Validators.required, Validators.minLength(3)]],
      phone: ['', [Validators.required, phoneValidator]],
      email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      invitation_text: [
        this.userRole === this.USER_ROLE.lender ? INVITE_AGENT_MESSAGE.join('') : INVITE_LENDER_MESSAGE.join(''),
        [
          Validators.maxLength(3000),
          Validators.required,
        ],
      ],
    });
  }

}
