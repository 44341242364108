<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Archive this Lead</h4>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="close()"></div>
  </div>
  <div class="modal-body">
    <div class="modal-body__text">
      You are archiving this lead. Updating and sending will be deactivated.
      <br/>Lead will be moved to the "Archive"
    </div>
    <div class="modal__accept m-t-4x" *ngIf="!mode">
      <form class="checkbox" [formGroup]="form">
        <input class="checkbox__input" id="show" type="checkbox" formControlName="show"/>
        <label class="checkbox__label" for="show">Do not show me this message again</label>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row row-fix">
      <div class="col col-fix">
        <iq-button class="block" label="Cancel" color="grey" [style]="'outline'" (onClick)="close()"></iq-button>
      </div>
      <div class="col col-fix">
        <iq-button class="block" label="Archive" (onClick)="confirm()"></iq-button>
      </div>
    </div>
  </div>
</div>
