import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError, required } from './conditions';

export const loanFormErros: FormErrors = {
  loan_type: [
    required,
    apiError,
  ],
  loan_amount: [
    required,
    apiError,
  ],
  interest_rate: [
    required,
    apiError,
  ],
  loan_term: [
    required,
    apiError,
  ],
  insurance_amount: [
    required,
    apiError,
  ],
  global: [],
};
