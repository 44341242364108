import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormErrors, ResponseErrorHandler, VaultService } from 'asap-team/asap-tools';

import type { Profile, RedirectInfo } from '@core/types';

// Consts
import { environment } from 'environments/environment';
import {
  ROUTE,
  EMAIL_PATTERN,
  JWT_TOKEN,
  signInErrors,
  QUERY_PARAM_VALUE,
  QUERY_PARAM_KEY,
  COMMON_TOAST,
  LAST_ROUTING_STATE,
} from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';

@UntilDestroy()
@Component({
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {

  @ViewChild('email', { static: true }) email: ElementRef<HTMLInputElement>;

  @ViewChild('password', { static: true }) password: ElementRef<HTMLInputElement>;

  isShowForm: boolean = false;

  ROUTE: any = ROUTE;

  formErrors: FormErrors = signInErrors;

  action: Subscription;

  form: FormGroup;

  signUpLink: string = `${environment.HomeIQLanding}`;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private builder: FormBuilder,
    private userService: UserService,
    private responseErrorHandler: ResponseErrorHandler,
    private vaultService: VaultService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.form = this.builder.group({
      email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });

    const profile: Profile = this.userService.syncGetProfile();

    if (!profile) {
      this.isShowForm = true;
      this.subscribes();

      return;
    }

    this.redirectToApp(this.route.snapshot.queryParams.action, profile);
  }

  signIn(): void {
    this.action = this
      .userService
      .login(this.form.value)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          const redirectInfo: RedirectInfo = this.vaultService.get(LAST_ROUTING_STATE);

          if (redirectInfo) {
            const navigationExtras: NavigationExtras = { queryParams: redirectInfo.queryParams };

            this.router.navigate([redirectInfo.path], navigationExtras);
            this.vaultService.remove(LAST_ROUTING_STATE);

            return;
          }

          this.router.navigate([ROUTE.alias.DASHBOARD_PRODUCTION]);
        },
        (error: any) => {
          this.responseErrorHandler.process(error, this.form, this.formErrors);
        },
      );
  }

  redirectToApp(action: string, profile: Profile): void {
    if (this.userService.isSuperAdmin(profile?.role)) {
      this.router.navigate([ROUTE.alias.USERS]);

      return;
    }

    if (!profile?.registration_completed && !!this.vaultService.get(JWT_TOKEN)) {
      this.router.navigate([ROUTE.alias.SIGN_UP]);

      return;
    }

    switch (action) {
      case QUERY_PARAM_VALUE.SHOW_IMPORT: {
        this.router.navigate([ROUTE.alias.CSV_UPLOAD]);

        break;
      }
      case QUERY_PARAM_VALUE.INVITE: {
        this.router.navigate([ROUTE.alias.PARTNERSHIP]);

        break;
      }
      case QUERY_PARAM_VALUE.INVITE_ACCEPT: {
        this.router.navigate(
          [ROUTE.alias.INCOMING_INVITATIONS],
          { queryParams: { [QUERY_PARAM_KEY.ACTION]: QUERY_PARAM_VALUE.INVITE_ACCEPT } },
        );

        break;
      }
      case QUERY_PARAM_VALUE.GRANT_ACCESS: {
        this
          .userService
          .grantAccess()
          .pipe(
            untilDestroyed(this),
          )
          .subscribe(
            () => {
              this.toastr.success(COMMON_TOAST.ACCESS_GRANTED);
              this.router.navigate([ROUTE.alias.DASHBOARD_PRODUCTION]);
            },
            () => {
              this.router.navigate([ROUTE.alias.DASHBOARD_PRODUCTION]);
            },
          );

        break;
      }
      default: {
        this.router.navigate([ROUTE.alias.DASHBOARD_PRODUCTION]);

        break;
      }
    }
  }

  private subscribes(): void {
    this
      .form
      .valueChanges
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => { this.formErrors.global = []; },
      );
  }

}
