<avatar-placeholder class="profile-actions__avatar"
                    [name]="(profile$ | async)?.name"
                    [image]="(profile$ | async)?.avatar?.small"></avatar-placeholder>

<div class="profile-actions__toggle inline-svg"
     [inlineSVG]="'#icon-chevron'"></div>

<div class="control-dropdown"
     #profileActionsDropdown="bs-dropdown"
     dropdown
     container="body"
     placement="bottom end">

  <div class="control-dropdown__menu dropdown-menu dropdown-menu-right"
       *dropdownMenu>
    <ng-container *ngIf="(profile$ | async)?.registration_completed">
      <ng-container *ngFor="let navItem of navigationItems">
        <hr class="control-dropdown__divider"
            *ngIf="navItem.highlighted" />

        <div class="control-dropdown__item"
             [routerLink]="navItem.routerLink"
             routerLinkActive="active">

          <div class="control-dropdown__icon inline-svg--24"
               *ngIf="navItem.icon"
               [inlineSVG]="navItem.icon"></div>
          <span class="control-dropdown__label"
                [class.f-14-600]="navItem.highlighted">{{ navItem.label }}</span>
        </div>
      </ng-container>
      <hr class="control-dropdown__divider"
          *ngIf="navigationItems.length" />
    </ng-container>

    <div class="control-dropdown__item"
         (click)="logout()">

      <div class="control-dropdown__icon inline-svg--24"
           [inlineSVG]="'#icon-menu-logout'"></div>
      <span class="control-dropdown__label">Log out</span>
    </div>
  </div>
</div>
