/**
 * Redirects can be LOCAL (RELATIVE) and ABSOLUTE.
 * LOCAL redirects replace a single URL segment with a different one.
 *
 * Example:
 * Current url: "*.domain/parent/child"
 * this.router.navigate(['other_child'])
 * Current url will be "*.domain/parent/other_child"
 *
 * Also RELATIVE redirects:
 * this.router.navigate(['some_route]);
 * this.router.navigate(['./some_route]); - note the leading dot sign
 * this.router.navigate(['./]);
 * this.router.navigate(['../]);
 *
 * ABSOLUTE redirects replace the whole URL. If the redirectTo value starts with a /, then it is an ABSOLUTE redirect.
 *
 * Example:
 * Current url: "*.domain/parent/child"
 * this.router.navigate(['/some_other_route'])
 * Current url will be "*.domain/some_other_route"
 *
 * Also ABSOLUTE redirects:
 * this.router.navigate(['/some_route]);
 * this.router.navigate(['/some_route/some_child]);
 *
 * The router supports directory-like syntax in a link parameters list to help guide route name lookup:
 * ./ or NO leading slash is RELATIVE to the current level.
 * ../ to go up one level in the route path.
 * You can combine RELATIVE navigation syntax with an ancestor path. If you must navigate to a sibling route,
 * you could use the ../<sibling> convention to go up one level, then over and down the sibling route path.
 *
 */

const ACCOUNT: string = 'account';
const ACCOUNTS: string = 'accounts';
const ACTIVE: string = 'active';
const ACTIVITY: string = 'activity';
const ADMINS: string = 'admins';
const ADMIN_SETTINGS: string = 'admin-settings';
const AGENT_SEARCH: string = 'agent-search';
const AGENT_DETAILS: string = 'agent-details';
const ANY: string = '**';
const ARCHIVE: string = 'archive';
const ARCHIVED_ACCOUNTS: string = 'archived-accounts';
const ARCHIVED_LEADS: string = 'archived-leads';
const BANNERS: string = 'banners';
const BILLING: string = 'billing';
const BUYERS: string = 'buyers';
const CHECKOUT: string = 'checkout';
const CLIENT_ERROR: string = 'client-error';
const COACHING: string = 'coaching';
const COLLEAGUES: string = 'colleagues';
const COMPLETE_ACCOUNT: string = 'complete_account';
const CONNECTED_AGENTS: string = 'connected-agents';
const CONNECTED_LENDERS: string = 'connected-lenders';
const CONFIRM: string = 'confirm';
const CONFIRM_EMAIL: string = 'confirm_email';
const CSV: string = 'csv';
const DASHBOARD: string = 'dashboard';
const DETAILS: string = 'details';
const EMAIL: string = 'email';
const EMAIL_SUBSCRIPTION: string = 'email-subscription';
const EMAIL_TEMPLATE: string = 'email-template';
const EMPTY: string = '';
const ENTERPRISE: string = 'enterprise';
const EXPIRED_LINK: string = 'expired-link';
const EXPLORE_PRODUCTION: string = 'explore-production';
const FACEBOOK: string = 'facebook';
const FEES: string = 'fees';
const FORECLOSURE: string = 'foreclosure';
const FTL_AUTH: string = 'ftl-auth';
const GROWING_YOUR_BUSINESS: string = 'growing-your-business';
const HELP: string = 'help';
const HER_PROMO: string = 'equity-report-promo';
const HOMEOWNERS: string = 'homeowners';
const ANALYTICS: string = 'analytics';
const HOME_IQ: string = 'home-iq';
const HOME_VALUE_LANDING: string = 'home-value-landing';
const HOME_WEALTH_LANDING: string = 'home-wealth-landing';
const IMPORT: string = 'import';
const INCOMING: string = 'incoming';
const INTEGRATIONS: string = 'integrations';
const INTELLIGENCE: string = 'intelligence';
const INVITE: string = 'invite';
const ILIST: string = 'ilist';
const PREAPPROVE: string = 'pre_approve_me';
const PRODUCTION: string = 'production';
const LANDING: string = 'landing';
const REPORTPAGE: string = 'report-page';
const REPORT_SETTINGS: string = 'report-settings';
const LEAD: string = 'lead';
const LEADS: string = 'leads';
const LENDING_PAD: string = 'lending_pad';
const LENDER_DETAILS: string = 'lender-details';
const LISTING: string = 'listing';
const LOAN_OFFICER_SEARCH: string = 'loan-officer-search';
const MARKETING_FLYERS: string = 'marketing-flyers';
const MORTGAGE: string = 'mortgage';
const NET_SHEET: string = 'net-sheet';
const NEW: string = 'new';
const NEW_ACCOUNTS: string = 'new-accounts';
const NEW_LEADS: string = 'new-leads';
const OUTGOING: string = 'outgoing';
const PARTNERS: string = 'partners';
const PARTNERSHIP: string = 'partnership';
const PENDING: string = 'pending';
const PREVIEW: string = 'preview';
const PRE_MOVER: string = 'pre-mover';
const PROCESSED: string = 'processed';
const PROFILE: string = 'profile';
const PROGRAMS: string = 'programs';
const PROMO: string = 'promo';
const PROMO_CODES: string = 'promo-codes';
const REALTOR_CONNECTION_KIT: string = 'realtor-connection-kit';
const REFINANCE: string = 'refinance';
const REPORT: string = 'report';
const REPORTS: string = 'reports';
const RESERVATION: string = 'reservation';
const RESOURCES: string = 'resources';
const RESTORE: string = 'restore';
const RESTRICTED_ACCESS: string = 'restricted-access';
const SELLER_TRAFFIC: string = 'seller-traffic';
const SERVICE_UNAVAILABLE: string = 'service-unavailable';
const SETTINGS: string = 'settings';
const SELLING_FEES: string = 'selling-fees';
const SIGN_IN: string = 'sign-in';
const SIGN_UP: string = 'sign-up';
const STAFF: string = 'staff';
const STAFF_USER: string = 'staff-user';
const TITLE_COMPANY: string = 'title-company';
const TRAFFIC: string = 'traffic';
const TRAINING: string = 'training';
const TRANSACTIONS: string = 'transactions';
const TRIAL: string = 'trial';
const TUTORIALS: string = 'tutorials';
const UPGRADE_SUBSCRIPTION: string = 'upgrade-subscription';
const CANCEL_SUBSCRIPTION: string = 'cancel-subscription';
const UPLOAD: string = 'upload';
const UPLOADS: string = 'uploads';
const USERS: string = 'users';
const VIDEO_GUIDES: string = 'video-guides';
const VIRAL: string = 'viral';
const WEALTH: string = 'wealth';
const ZAPIER: string = 'zapier';
const REFERRAL: string = 'referral';
const REFERRAL_LIST: string = 'list';
const REFERRAL_MANAGE: string = 'manage';
const REFERRAL_EXPIRED: string = 'expired';
const HOME_SHOPPERS: string = 'home-shoppers';

export const ROUTE: any = {
  EMPTY,
  ANY,
  name: {
    ACCOUNT,
    ACCOUNTS,
    ACTIVE,
    ACTIVITY,
    ADMINS,
    ADMIN_SETTINGS,
    AGENT_SEARCH,
    COLLEAGUES,
    ARCHIVE,
    ARCHIVED_ACCOUNTS,
    ARCHIVED_LEADS,
    AGENT_DETAILS,
    BANNERS,
    BILLING,
    BUYERS,
    CHECKOUT,
    CLIENT_ERROR,
    COACHING,
    COMPLETE_ACCOUNT,
    CONNECTED_AGENTS,
    CONNECTED_LENDERS,
    CONFIRM,
    CONFIRM_EMAIL,
    CSV,
    DASHBOARD,
    DETAILS,
    EMAIL,
    EMAIL_SUBSCRIPTION,
    EMAIL_TEMPLATE,
    ENTERPRISE,
    EXPIRED_LINK,
    EXPLORE_PRODUCTION,
    FACEBOOK,
    FEES,
    FORECLOSURE,
    FTL_AUTH,
    GROWING_YOUR_BUSINESS,
    HELP,
    HER_PROMO,
    HOMEOWNERS,
    ANALYTICS,
    HOME_IQ,
    HOME_VALUE_LANDING,
    HOME_WEALTH_LANDING,
    IMPORT,
    INCOMING,
    INTEGRATIONS,
    INTELLIGENCE,
    INVITE,
    ILIST,
    PREAPPROVE,
    LANDING,
    LEAD,
    LEADS,
    LENDING_PAD,
    LENDER_DETAILS,
    LISTING,
    LOAN_OFFICER_SEARCH,
    MARKETING_FLYERS,
    MORTGAGE,
    NET_SHEET,
    NEW,
    NEW_ACCOUNTS,
    NEW_LEADS,
    OUTGOING,
    PARTNERS,
    PARTNERSHIP,
    PENDING,
    PREVIEW,
    PRE_MOVER,
    PROCESSED,
    PRODUCTION,
    PROFILE,
    PROGRAMS,
    PROMO,
    PROMO_CODES,
    REALTOR_CONNECTION_KIT,
    REFINANCE,
    HOME_SHOPPERS,
    REPORTS,
    REPORT,
    REPORTPAGE,
    REPORT_SETTINGS,
    RESERVATION,
    RESOURCES,
    RESTORE,
    RESTRICTED_ACCESS,
    REFERRAL,
    REFERRAL_LIST,
    REFERRAL_MANAGE,
    REFERRAL_EXPIRED,
    SERVICE_UNAVAILABLE,
    SETTINGS,
    SELLING_FEES,
    SIGN_IN,
    SIGN_UP,
    STAFF,
    STAFF_USER,
    TITLE_COMPANY,
    TRAFFIC,
    TRAINING,
    TRANSACTIONS,
    TRIAL,
    TUTORIALS,
    UPGRADE_SUBSCRIPTION,
    CANCEL_SUBSCRIPTION,
    UPLOAD,
    UPLOADS,
    USERS,
    VIDEO_GUIDES,
    VIRAL,
    ZAPIER,
    SELLER_TRAFFIC,
    WEALTH,
  },
  alias: {
    // single
    ACCOUNT: `/${ACCOUNT}`,
    ACCOUNTS: `/${ACCOUNTS}`,
    ACTIVITY: `${ACTIVITY}`,
    ADMIN_SETTINGS: `/${ADMIN_SETTINGS}`,
    ARCHIVED_ACCOUNTS: `/${ARCHIVED_ACCOUNTS}`,
    ARCHIVED_LEADS: `/${ARCHIVED_LEADS}`,
    BANNERS: `/${BANNERS}`,
    BUYERS: `/${BUYERS}`,
    CHECKOUT: `${CHECKOUT}`,
    CLIENT_ERROR: `/${CLIENT_ERROR}`,
    COACHING: `/${COACHING}`,
    CONFIRM_EMAIL: `/${CONFIRM_EMAIL}`,
    DASHBOARD: `/${DASHBOARD}`,
    EMAIL_SUBSCRIPTION: `/${EMAIL_SUBSCRIPTION}`,
    ENTERPRISE: `/${ENTERPRISE}`,
    EXPIRED_LINK: `/${EXPIRED_LINK}`,
    EXPLORE_PRODUCTION: `${DASHBOARD}/${EXPLORE_PRODUCTION}`,
    FORECLOSURE: `${FORECLOSURE}`,
    GROWING_YOUR_BUSINESS: `/${GROWING_YOUR_BUSINESS}`,
    HELP: `/${HELP}`,
    HER_PROMO: `/${HER_PROMO}`,
    HOMEOWNERS: `/${HOMEOWNERS}`,
    ANALYTICS: `/${ANALYTICS}`,
    HOME_IQ: `/${HOME_IQ}`,
    INVITE: `/${INVITE}`,
    LISTING: `/${LISTING}`,
    MORTGAGE: `/${MORTGAGE}`,
    NET_SHEET: `${NET_SHEET}`,
    NEW_ACCOUNTS: `/${NEW_ACCOUNTS}`,
    NEW_LEADS: `/${NEW_LEADS}`,
    PARTNERS: `/${PARTNERS}`,
    PARTNERSHIP: `/${PARTNERSHIP}`,
    PRE_MOVER: `${PRE_MOVER}`,
    PROMO: `/${PROMO}`,
    PROMO_CODES: `/${PROMO_CODES}`,
    REALTOR_CONNECTION_KIT: `/${REALTOR_CONNECTION_KIT}`,
    REFERRAL_LIST: `/${REFERRAL}/${REFERRAL_LIST}`,
    REFERRAL_MANAGE: `/${REFERRAL}/${REFERRAL_MANAGE}`,
    REFERRAL_EXPIRED: `/${REFERRAL}/${REFERRAL_EXPIRED}`,
    REFINANCE: `${REFINANCE}`,
    REPORTS: `${REPORTS}`,
    SERVICE_UNAVAILABLE: `/${SERVICE_UNAVAILABLE}`,
    SETTINGS: `/${SETTINGS}`,
    SIGN_IN: `/${SIGN_IN}`,
    SIGN_UP: `/${SIGN_UP}`,
    STAFF: `/${STAFF}`,
    STAFF_USER: `/${STAFF_USER}`,
    TITLE_COMPANY: `/${TITLE_COMPANY}`,
    TRAFFIC: `${TRAFFIC}`,
    TRAINING: `${TRAINING}`,
    TRIAL_ACCOUNTS: `/${TRIAL}`,
    UPGRADE_SUBSCRIPTION: `/${UPGRADE_SUBSCRIPTION}`,
    UPLOADS: `/${UPLOADS}`,
    USERS: `/${USERS}`,
    VIDEO_GUIDES: `/${VIDEO_GUIDES}`,
    VIRAL: `/${VIRAL}`,
    // compounded
    ACTIVE_PARTNERS: `/${PARTNERSHIP}/${ACTIVE}`,
    ADMIN_SETTINGS_PROFILE: `/${ADMIN_SETTINGS}/${PROFILE}`,
    BANNERS_BUYERS: `/${BANNERS}/${BUYERS}`,
    BANNERS_HOMEOWNERS: `/${BANNERS}/${HOMEOWNERS}`,
    BANNERS_NEW: `/${BANNERS}/${NEW}`,
    BILLING: `/${SETTINGS}/${BILLING}`,
    SUBSCRIPTION_CANCELING: `/${SETTINGS}/${BILLING}/${CANCEL_SUBSCRIPTION}`,
    BUYERS_BANNERS: `/${BUYERS}/${BANNERS}`,
    BUYERS_FEES: `/${SETTINGS}/${BUYERS}/${FEES}`,
    BUYERS_LEAD: `/${BUYERS}/${LEAD}`,
    BUYERS_LP_PREVIEW: `/${SETTINGS}/${BUYERS}/${LANDING}/${PREVIEW}`,
    BUYERS_LP_PROGRAMS: `/${SETTINGS}/${BUYERS}/${LANDING}/${PROGRAMS}`,
    BUYERS_SETTINGS: `/${SETTINGS}/${BUYERS}`,
    BUYERS_TRAFFIC: `/${BUYERS}/${TRAFFIC}`,
    CSV_IMPORT: `/${CSV}/${IMPORT}`,
    CSV_UPLOAD: `/${CSV}/${UPLOAD}`,
    DASHBOARD_PRODUCTION: `/${DASHBOARD}/${PRODUCTION}`,
    EMAIL_EDITOR: `/${SETTINGS}/${EMAIL}`,
    EXPLORE_PRODUCTION_TRANSACTIONS: `${DASHBOARD}/${EXPLORE_PRODUCTION}/${TRANSACTIONS}`,
    FEES: `/${SETTINGS}/${FEES}`,
    ANALYTICS_HOME_SHOPPERS: `/${ANALYTICS}/${HOME_SHOPPERS}`,
    HOMEOWNERS_ACTIVITY: `/${HOMEOWNERS}/${ACTIVITY}`,
    HOMEOWNERS_BANNERS: `/${HOMEOWNERS}/${BANNERS}`,
    HOMEOWNERS_EMAIL: `/${SETTINGS}/${HOMEOWNERS}/${EMAIL_TEMPLATE}`,
    HOMEOWNERS_FEES: `/${SETTINGS}/${HOMEOWNERS}/${FEES}`,
    HOMEOWNERS_SELLING_FEES: `/${SETTINGS}/${HOMEOWNERS}/${SELLING_FEES}`,
    HOMEOWNERS_LEAD_NEW: `/${HOMEOWNERS}/${LEAD}/${NEW}`,
    HOMEOWNERS_LEAD: `/${HOMEOWNERS}/${LEAD}`,
    HOMEOWNERS_REPORT_PAGE: `/${SETTINGS}/${HOMEOWNERS}/${REPORTPAGE}`,
    REPORT_SETTINGS_PAGE: `/${SETTINGS}/${HOMEOWNERS}/${REPORT_SETTINGS}`,
    HOMEOWNERS_MORTGAGE: `/${HOMEOWNERS}/${MORTGAGE}`,
    HOMEOWNERS_REPORTS: `/${HOMEOWNERS}/${REPORTS}`,
    HOMEOWNERS_REPORT: `/${HOMEOWNERS}/${REPORT}`,
    HOMEOWNERS_REPORT_NEW: `/${HOMEOWNERS}/${REPORT}/${NEW}`,
    HOMEOWNERS_SETTINGS: `/${SETTINGS}/${HOMEOWNERS}`,
    HOMEOWNERS_SETTINGS_LANDING_PAGES: `/${SETTINGS}/${HOMEOWNERS}/${LANDING}`,
    HOMEOWNERS_LP: `/${HOMEOWNERS}/${LANDING}`,
    HOMEOWNERS_TRAFFIC: `/${HOMEOWNERS}/${TRAFFIC}`,
    HOME_VALUE_LANDING: `/${SETTINGS}/${HOMEOWNERS}/${LANDING}/${HOME_VALUE_LANDING}`,
    HOME_WEALTH_LANDING: `/${SETTINGS}/${HOMEOWNERS}/${LANDING}/${HOME_WEALTH_LANDING}`,
    INCOMING_INVITATIONS: `/${PARTNERSHIP}/${INCOMING}`,
    INTELLIGENCE_COLLEAGUES: `/${PARTNERSHIP}/${INTELLIGENCE}/${COLLEAGUES}`,
    INTELLIGENCE_CONNECTED_AGENTS: `/${PARTNERSHIP}/${INTELLIGENCE}/${CONNECTED_AGENTS}`,
    INTELLIGENCE_AGENT_SEARCH: `/${PARTNERSHIP}/${INTELLIGENCE}/${AGENT_SEARCH}`,
    INTELLIGENCE_AGENT_DETAILS: `/${PARTNERSHIP}/${AGENT_DETAILS}`,
    INTELLIGENCE_LENDER_DETAILS: `/${PARTNERSHIP}/${LENDER_DETAILS}`,
    INTELLIGENCE_LOAN_OFFICER_SEARCH: `/${PARTNERSHIP}/${INTELLIGENCE}/${LOAN_OFFICER_SEARCH}`,
    INTEGRATIONS: `/${SETTINGS}/${INTEGRATIONS}`,
    NEW_STAFF_USER: `/${STAFF_USER}/${NEW}`,
    OUTGOING_INVITATIONS: `/${PARTNERSHIP}/${OUTGOING}`,
    PARTNERS_ENTERPRISE: `/${PARTNERS}/${ENTERPRISE}`,
    PARTNERS_NEW: `/${PARTNERS}/${NEW}`,
    PARTNERSHIP_CONNECTED_LENDERS: `/${PARTNERSHIP}/${CONNECTED_LENDERS}`,
    PROFILE: `/${SETTINGS}/${PROFILE}`,
    RESOURCES: `/${HELP}/${RESOURCES}`,
    RESTORE: `/${SIGN_IN}/${RESTORE}`,
    SIGN_UP_EXPIRED: `/${SIGN_UP}/${EXPIRED_LINK}`,
    TUTORIALS: `/${HELP}/${TUTORIALS}`,
    TRAINING_VIDEO_GUIDES: `/${TRAINING}/${VIDEO_GUIDES}`,
    TRAINING_REALTOR_CONNECTION_KIT: `/${TRAINING}/${REALTOR_CONNECTION_KIT}`,
    TRAINING_GROWING_YOUR_BUSINESS: `/${TRAINING}/${GROWING_YOUR_BUSINESS}`,
    TRAINING_MARKETING_FLYERS: `/${TRAINING}/${MARKETING_FLYERS}`,
    TRAINING_COACHING: `/${TRAINING}/${COACHING}`,
    USERS_HOME_IQ: `/${USERS}/${HOME_IQ}`,
    USERS_HOME_IQ_TRIAL: `/${USERS}/${HOME_IQ}/${TRIAL}`,
  },
};
