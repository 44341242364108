import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import {
  APIErrorResponse, FormErrors, ModalService, phoneValidator, ResponseErrorHandler,
} from 'asap-team/asap-tools';

import { Avatar, CheckboxItem, Profile } from '@core/types';

// Const
import { profileErrors } from '@consts/form-errors';
import { JOB_TITLE_PATTERN } from '@consts/regex';

// Components
import { ConfirmPhoneComponent } from '@commons/modals/confirm-phone/confirm-phone.component';

// Services
import { DictionaryService } from '@core/services/dictionary/dictionary.service';
import { UserService } from '@core/services/user/user.service';

// State
import { FTLActions } from '@modules/auth/sign-up/ftl/state/ftl.actions';
import { FTLStepName } from '@modules/auth/sign-up/ftl/state/ftl.model';
import { FtlState } from '@modules/auth/sign-up/ftl/state/ftl.state';

@UntilDestroy()
@Component({
  selector: 'ftl-agent-profile',
  templateUrl: './ftl-agent-profile.component.html',
  styleUrls: ['../ftl.component.scss', './ftl-agent-profile.component.scss'],
})
export class FtlAgentProfileComponent {

  profile: Profile = null;

  states: CheckboxItem[] = this.route.snapshot.data?.states;

  timeZones$: Observable<CheckboxItem []> = this.dictionaryService.timeZones$;

  form: FormGroup = this.fb.group({
    phone: ['', [Validators.required, phoneValidator]],
    license_number: ['', [Validators.required, Validators.minLength(3)]],
    mls_number: ['', [Validators.required, Validators.minLength(3)]],
    company_name: ['', [Validators.required]],
    company_license_number: ['', [Validators.required]],
    license_states: ['', [Validators.required]],
    job_title: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(JOB_TITLE_PATTERN)]],
    time_zone: ['', [Validators.required]],
  });

  formErrors: FormErrors = profileErrors;

  avatarLoading: boolean = false;

  action$: Subscription;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private dictionaryService: DictionaryService,
    private userService: UserService,
    private modalService: ModalService,
    private responseErrorHandler: ResponseErrorHandler,
  ) {}

  get isConfirmPhoneDisabled(): boolean {
    return !(this.profile?.phone && (this.profile?.phone === this.form.get('phone').value));
  }

  ngOnInit(): void {
    this.store
      .dispatch(new FTLActions.GetStepDataAction(FTLStepName.agent_completed_profile))
      .subscribe(() => {
        const { profile } = this.store.selectSnapshot(FtlState.ftlStepData);

        if (profile) {
          this.profile = profile;
          this.patchForm(profile);
        }
      });
  }

  uploadAvatar({ image, name }: { image: Blob; name: string }): void {
    this.avatarLoading = true;

    this.userService.uploadAvatar(image, name)
      .pipe(
        untilDestroyed(this),
        catchError((error: any) => {
          this.avatarLoading = false;

          return throwError(error);
        }),
      )
      .subscribe((avatar: Avatar) => {
        this.avatarLoading = false;
        this.profile = {
          ...this.profile,
          avatar,
        };
      });
  }

  confirmPhoneNumber(): void {
    this
      .modalService
      .open(ConfirmPhoneComponent)
      .pipe(
        filter(Boolean),
        untilDestroyed(this),
      )
      .subscribe();
  }

  submitAgentProfile(): void {
    if (this.form.valid) {
      this.action$ = this.store
        .dispatch(new FTLActions.SubmitFtlStepAction(FTLStepName.agent_completed_profile, this.form.value))
        .subscribe(
          () => {},
          (error: APIErrorResponse) => {
            this.responseErrorHandler.process(error, this.form, this.formErrors);
          },
        );
    }
  }

  private patchForm(profile: Profile): void {
    const {
      phone,
      license_number,
      mls_number,
      company_name,
      company_license_number,
      license_states,
      job_title,
      time_zone,
    } = profile;

    if (phone) {
      this.form.get('phone').setValue(phone);
    }

    this.form.patchValue({
      license_number,
      mls_number,
      company_name,
      company_license_number,
      license_states: license_states.join(),
      job_title,
      time_zone,
    });
  }

}
