import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError } from './conditions';

export const promoCodeErrors: FormErrors = {
  code: [
    {
      name: 'required',
      text: 'Code can’t be blank',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'minlength',
      text: 'Min length is 3',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'noWhiteSpace',
      text: 'Empty spaces are not allowed',
      rules: ['dirty'],
    },
    apiError,
  ],
  owner_name: [
    {
      name: 'minlength',
      text: 'Min length is 3',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'noWhiteSpace',
      text: 'Empty spaces are not allowed',
      rules: ['dirty'],
    },
    apiError,
  ],
  subscription_discount_percentage: [
    {
      name: 'max',
      text: 'Max value is 100%',
      rules: ['touched', 'dirty'],
    },
    apiError,
  ],
  setup_fee_discount_percentage: [
    {
      name: 'max',
      text: 'Max value is 100%',
      rules: ['touched', 'dirty'],
    },
    apiError,
  ],
  global: [],
};
