<div class="placeholder">
  <p class="placeholder__title f-32-bold" [innerHTML]="copy?.title"></p>
  <p class="placeholder__subtitle f-16-normal" [innerHTML]="copy?.subtitle"></p>
  <div class="placeholder__buttons">
    <ng-container *ngIf="isSubscriptionStatusValid && isAppEnabled; else resumeSubscriptionButton">
      <iq-button *ngIf="copy?.button" [label]="copy?.button" (onClick)="invite()"></iq-button>
      <a class="btn" [href]="landingUrl" target="_blank">
        <iq-button [label]="copy?.outlineButton" [style]="'outline'"></iq-button>
      </a>
    </ng-container>
    <ng-template #resumeSubscriptionButton="">
      <div *permittedIf="PERMITTED_ACTION.BUYER_LEADS_STATE_PROMO">
        <a href="mailto:support@myhomeiq.com">
          <iq-button label="Contact support"></iq-button>
        </a>
      </div>
      <div *permittedIf="PERMITTED_ACTION.BUYER_LEADS_LOCK_SUBSCRIPTION_RENEW">
        <iq-button label="Renew subscription" (click)="resumeSubscription()"></iq-button>
      </div>
    </ng-template>
  </div>
</div>
<div class="placeholder__image">
  <img src="assets/components/collections/traffic/buyer-placeholder.png"/>
  <button class="placeholder__image--button" (click)="showVideoModal()">
    <img src="assets/components/collections/traffic/play-button.svg"/>
  </button>
</div>
