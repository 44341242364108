import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError, required } from './conditions';

export const newOrderErrors: FormErrors = {
  limit: [
    required,
    {
      name: 'min',
      text: 'Min length is 1',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'max',
      text: 'Must be less than 1,000,000',
      rules: ['dirty'],
    },
    apiError,
  ],
  month_number: [
    required,
    apiError,
  ],
  amount: [
    required,
    {
      name: 'min',
      text: 'Min length is 1',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'max',
      text: 'Must be less than $100,000',
      rules: ['dirty'],
    },
    apiError,
  ],
  price_per_lead: [
    required,
    {
      name: 'min',
      text: 'Min length is 1',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'max',
      text: 'Must be less than $10',
      rules: ['dirty'],
    },
    apiError,
  ],
  applications: [
    required,
    apiError,
  ],
  plan_name: [
    apiError,
  ],
  paid_leads_allowed: [
    required,
    apiError,
  ],
  partnerships_limit: [
    required,
    {
      name: 'min',
      text: 'Min length is 1',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'max',
      text: 'Must be less than 1,000,000',
      rules: ['dirty'],
    },
    apiError,
  ],
  global: [],
};
