import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError, required } from './conditions';

export const signUpErrors: FormErrors = {
  password: [
    {
      name: 'minlength',
      text: 'Min length is 6',
      rules: ['touched', 'dirty'],
    },
  ],
  password_confirmation: [
    {
      name: 'minlength',
      text: 'Min length is 6',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'matchPassword',
      text: 'Password not match',
      rules: ['touched', 'dirty'],
    },
  ],
  name: [
    {
      name: 'minlength',
      text: 'Min length is 3',
      rules: ['touched', 'dirty'],
    },
    apiError,
    required,
  ],
  email: [
    required,
    {
      name: 'pattern',
      text: 'Email is invalid',
      rules: ['touched', 'dirty'],
    },
    apiError,
  ],
  global: [],
};
