<form class="modal-content add-card-form" novalidate="" (ngSubmit)="addCard()">
  <div class="modal-header">
    <h4 class="modal-title">Add card</h4>
  </div>
  <div class="modal-body">
    <div class="new-card" id="new-card">
      <loading-dots class="visible"></loading-dots>
    </div>
    <div class="control-error-message" *ngFor="let error of formErrors.global">{{ error }}</div>
    <div class="checkbox text-left" *ngIf="showPrimaryFlag && !primaryByDefault">
      <input class="checkbox__input"
             id="setPrimaryCardCheckbox"
             type="checkbox"
             [checked]="primary"
             (change)="primary = !primary"/>
      <label class="checkbox__label f-14-normal f-c-grey-main" for="setPrimaryCardCheckbox">
        Make this my primary card
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row row-fix">
      <div class="col col-fix">
        <iq-button class="block" label="Cancel" color="red" [style]="'outline'" (onClick)="close()"></iq-button>
      </div>
      <div class="col col-fix">
        <iq-button class="block" type="submit" label="Add card" [busy$]="addCardAction"></iq-button>
      </div>
    </div>
  </div>
</form>
