import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { throwError } from 'rxjs';
import { debounceTime, catchError, filter } from 'rxjs/operators';

import type { Dictionary, FormErrors } from 'asap-team/asap-tools';

// Consts
import { COMMON_TOAST, trackingIdErrors } from '@consts/consts';

// Services
import { SettingsService } from '@core/services/settings/settings.service';

@UntilDestroy()
@Component({
  selector: 'tracking-id',
  templateUrl: './tracking-id.component.html',
  styleUrls: ['./tracking-id.component.scss'],
})
export class TrackingIdComponent implements OnInit, OnChanges {

  @Input() label: string;

  @Input() placeholder: string;

  @Input() type: string;

  @Input() api: string;

  @Input() value: string;

  form: FormGroup = this.fb.group({});

  isSaving: boolean = false;

  formErrors: FormErrors = trackingIdErrors;

  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.subscribes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.value?.currentValue) {
      this.form.get('id').patchValue(changes.value.currentValue, { emitEvent: false, onlySelf: true });
    }
  }

  save(value: Dictionary): void {
    if (!value || !this.api || !this.type) { return; }

    this.isSaving = true;
    this
      .settingsService
      .saveTrackingId(this.api, { [this.type]: value.id })
      .pipe(
        catchError((error: any) => {
          this.isSaving = false;

          return throwError(error);
        }),
      )
      .subscribe(() => {
        this.isSaving = false;
        this.toastrService.success(value.id ? COMMON_TOAST.SAVED : COMMON_TOAST.REMOVED);
      });
  }

  private subscribes(): void {
    this
      .form
      .valueChanges
      .pipe(
        debounceTime(300),
        filter(() => this.form.status === 'VALID'),
        untilDestroyed(this),
      )
      .subscribe(this.save.bind(this));
  }

  private createForm(): void {
    this.settingsService.createTrackingIdFormControl(this.form, this.type, this.value);
  }

}
