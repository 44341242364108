<div class="placeholder">
  <p class="placeholder__title f-32-bold" [innerHTML]="copy?.title"></p>
  <p class="placeholder__subtitle f-16-normal" [innerHTML]="copy?.subtitle"></p>
  <div class="placeholder__buttons" *permittedIf="PERMITTED_ACTION.HOME_WEALTH_LANDING_MANAGE else default">
    <iq-button class="placeholder__button"
               [label]="copy?.homeWealthButton"
               [style]="'outline'"
               (onClick)="goToHomeWealthLanding()"
               classes="w-100"></iq-button>
    <iq-button class="placeholder__button"
               [label]="copy?.homeValueButton"
               [style]="'outline'"
               (click)="goToHomeValueLanding()"
               classes="w-100"></iq-button>
  </div>
  <ng-template #default="">
    <div class="placeholder__buttons"
         *permittedIf="PERMITTED_ACTION.HOMEOWNER_LEADS_LOCK_SUBSCRIPTION_RENEW">
      <iq-button [label]="copy?.button" (onClick)="invite()"></iq-button>
      <iq-button *ngIf="copy?.outlineButton"
                 [label]="copy?.outlineButton"
                 [style]="'outline'"
                 (click)="showGetLinkModal()"></iq-button>
    </div>
    <div class="flex-center m-b-5x" *permittedIf="PERMITTED_ACTION.HOMEOWNER_LEADS_STATE_PROMO">
      <a href="mailto:support@myhomeiq.com">
        <iq-button label="Contact support"></iq-button>
      </a>
    </div>
  </ng-template>
</div>
<div class="placeholder__image"><img [src]="getBackgroundImg"/>
  <button class="placeholder__image--button" (click)="showVideoModal()">
    <img src="assets/components/collections/traffic/play-button.svg"/>
  </button>
</div>
