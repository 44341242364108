import {
  Component, OnInit, Input, Output, EventEmitter,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import type { Dictionary, FormErrors } from 'asap-team/asap-tools';

// Consts
import { currencyFilterErrors } from '@consts/consts';

// Validators
import { conditionalValidator } from '@core/validators/conditional/conditional.validator';

@UntilDestroy()
@Component({
  selector: 'iq-currency-filter',
  templateUrl: './iq-currency-filter.component.html',
  styleUrls: ['./iq-currency-filter.component.scss'],
})
export class IqCurrencyFilterComponent implements OnInit {

  @Input() title: string;

  @Input() name: string;

  @Input() filterName: string;

  @Input() stateValueFrom: string;

  @Input() stateValueTo: string;

  @Output() emitFilterValue: EventEmitter<{ filterName: string; value: any }> = new EventEmitter();

  form: FormGroup;

  formErrors: FormErrors = currencyFilterErrors;

  get selected(): string {
    if (parseInt(this.form?.value?.min, 10) || parseInt(this.form?.value?.max, 10)) {
      return `$${this.decimalPipe.transform(this.form.value.min || 0)} - $${this.decimalPipe.transform(this.form.value.max || 0)}`;
    }

    return '';
  }

  constructor(
    private builder: FormBuilder,
    private decimalPipe: DecimalPipe,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.subscribes();
  }

  clearValues(): void {
    this.form.reset({ min: { value: '0', disabled: true }, max: '0' }, { emitEvent: false });
    this.form.markAsUntouched();
    this.emitFilterValue.emit({
      filterName: this.filterName,
      value: {
        [`${this.filterName}_from`]: null,
        [`${this.filterName}_to`]: null,
      },
    });
  }

  private initForm(): void {
    this.form = this.builder.group({
      min: [{ value: '0', disabled: true }],
      max: ['0', [Validators.required, Validators.max(499999999)]],
    });

    this
      .form
      .get('min')
      .setValidators([
        Validators.required,
        Validators.max(499999999),
        conditionalValidator(
          () => this.form.get('min').value >= this.form.get('max').value,
          { moreThanMax: Validators.max(this.form.get('max').value) },
        ),
      ]);
  }

  private subscribes(): void {
    this
      .form
      .valueChanges
      .pipe(
        distinctUntilChanged(isEqual),
        debounceTime(500),
        untilDestroyed(this),
      )
      .subscribe((form: Dictionary<string>) => {
        const { min, max } = form;
        const minControl: AbstractControl = this.form.get('min');

        minControl.updateValueAndValidity();

        if (this.form.invalid) { return; }

        if (+max > 0) {
          minControl.enable({ emitEvent: false });
        } else {
          minControl.disable({ emitEvent: false });
          this.emitFilterValue.emit({
            filterName: this.filterName,
            value: {
              [`${this.filterName}_from`]: null,
              [`${this.filterName}_to`]: null,
            },
          });

          return;
        }

        const currentMin: number = +min || 0;
        const currentMax: number = +max;

        if (currentMin >= currentMax) { return; }

        this.emitFilterValue.emit({
          filterName: this.filterName,
          value: {
            [`${this.filterName}_from`]: currentMin,
            [`${this.filterName}_to`]: currentMax,
          },
        });
      });
  }

}
