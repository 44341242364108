import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError } from './conditions';

export const trackingIdErrors: FormErrors = {
  id: [
    {
      name: 'pattern',
      text: 'Tracking ID is invalid',
      rules: ['touched', 'dirty'],
    },
    apiError,
  ],
  global: [],
};
