import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormErrors } from 'asap-team/asap-tools';

import type { MortgageCheckupItem, NewRateModalResult, NewRateResponse } from '@core/types';

// Consts
import { ROUTE, MORTGAGE_CHECKUP, newInterestRateErrors } from '@consts/consts';

// Services
import { MortgageCheckupsService } from '@core/services/collections/mortgage-checkup/mortgage-checkup.service';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { WINDOW } from '@ng-web-apis/common';

@UntilDestroy()
@Component({
  templateUrl: './change-mortgage-rate.component.html',
  styleUrls: ['./change-mortgage-rate.component.scss'],
})
export class ChangeMortgageRateComponent
  extends SimpleModalComponent<{ item: MortgageCheckupItem }, { item?: NewRateModalResult; action?: string }>
  implements OnInit {

  ROUTE: any = ROUTE;

  item: MortgageCheckupItem;

  action$: Subscription;

  form: FormGroup;

  formErrors: FormErrors = newInterestRateErrors;

  isDownloading: boolean = false;

  constructor(
    @Inject(WINDOW) private window: any,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private mortgageCheckupsService: MortgageCheckupsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group(
      { new_interest_rate: [this.item.new_interest_rate, [Validators.required, Validators.min(0.01), Validators.max(10)]] },
    );
  }

  submit(): void {
    const { id, loan_term } = this.item;
    const { new_interest_rate } = this.form.value;

    this.action$ = this
      .mortgageCheckupsService
      .newInterestRate(id, { loan_term, new_interest_rate })
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((response: NewRateResponse) => {
        this.toastr.success(MORTGAGE_CHECKUP.NEW_RATE);
        this.result = {
          item: {
            id,
            loan_term,
            monthly_savings: response.monthly_savings,
            new_interest_rate: response.new_interest_rate,
            current_interest_rate: response.current_interest_rate,
            apr: response.apr,
          },
        };
        this.close();
      });
  }

  downloadFile(leadId: string, loan_term: number): void {
    const uid: string = leadId.split('.')[0];

    this.isDownloading = true;
    this
      .mortgageCheckupsService
      .download(uid, loan_term)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        ({ file, name }: { file: Blob; name: string }) => {
          this.toastr.success(MORTGAGE_CHECKUP.PDF_GENERATED);
          this.window.saveAs(file, name);
          this.isDownloading = false;
        },
      );
  }

  sendQuote(): void {
    this.result = { item: this.item, action: 'send_quote' };
    this.close();
  }

  download(): void {
    this.downloadFile(this.item.id, this.item.loan_term);
  }

}
