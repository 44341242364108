import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError } from './conditions';

export const newUserErrors: FormErrors = {
  role: [
    {
      name: 'required',
      text: 'Code can’t be blank',
      rules: ['touched', 'dirty'],
    },
    apiError,
  ],
  username: [
    {
      name: 'required',
      text: 'Name is required',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'minlength',
      text: 'Min length is 3',
      rules: ['touched', 'dirty'],
    },
    apiError,
  ],
  email: [
    {
      name: 'required',
      text: 'Email address is required',
      rules: ['touched', 'dirty'],
    },
    apiError,
  ],
  phone: [
    {
      name: 'required',
      text: 'Phone number is required',
      rules: ['touched', 'dirty'],
    },
    apiError,
  ],
  global: [],
};
