import { Component, Input } from '@angular/core';

@Component({
  selector: 'relationships-list-item',
  templateUrl: './relationships-list-item.component.html',
  styleUrls: ['../relationships-list.component.scss'],
})
export class RelationshipsListItemComponent {

  @Input() relationship: any;

  @Input() link: string = null;

  @Input() linkState: { route: string; title: string } = null;

}
