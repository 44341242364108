import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError, required, invalidPhone } from './conditions';

export const newRegistrationErrors: FormErrors = {
  application: [
    required,
  ],
  name: [
    required,
    {
      name: 'minlength',
      text: 'Min length is 3',
      rules: ['touched', 'dirty'],
    },
    apiError,
  ],
  email: [
    required,
    {
      name: 'pattern',
      text: 'Email is invalid',
      rules: ['touched', 'dirty'],
    },
    apiError,
  ],
  phone: [
    required,
    invalidPhone,
    apiError,
  ],
  amount: [
    required,
    {
      name: 'minValueError',
      text: 'Min length is 1',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'maxValueError',
      text: 'Must be less than $10,000',
      rules: ['dirty'],
    },
    apiError,
  ],
  description: [
    required,
    {
      name: 'minlength',
      text: 'Min length is 3',
      rules: ['touched', 'dirty'],
    },
    apiError,
  ],
  global: [],
};
