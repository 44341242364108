import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_BASE_HREF } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgModule, ErrorHandler, Provider } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import {
  APIInterceptor,
  API_INTECEPTOR_CONFIG,
  AuthTokenInterceptor,
  AUTH_TOKEN_CONFIG,
  GLOBAL_ERROR_STATUS_HANDLER_TOKEN,
  JSONApiDeserializerInterceptor,
  SentinelErrorHandler,
  SENTINEL_CONFIG,
  USER_SERVICE_TOKEN,
} from 'asap-team/asap-tools';

// Consts
import { environment } from 'environments/environment';
import { JWT_TOKEN } from '@consts/consts';

// Interceptors
import { RequestCachingInterceptor } from '@core/interceptors/request-caching/request-caching.interceptor';
import { ProfileHashInterceptor } from '@core/interceptors/profile-hash/profile-hash.interceptor';

// Services
import { UserServiceProxy } from '@core/services/user/user.service.proxy';
import { GlobalErrorStatusHandler } from '@core/helpers/global-error-status-handler/global-error-status-handler';

// Global State
import { FtlState } from '@modules/auth/sign-up/ftl/state/ftl.state';
import { SearchPartnerSidebarState } from '@commons/sidebars/search-partner-sidebar/state/search-partner-sidebar.state';

// Modules
import { AuthModule } from '@modules/auth/auth.module';
import { CommonsModule } from '@commons/commons.module';
import { AppRoutingModule } from './routes/app-routing.module';

// Components
import { AppComponent } from './app.component';

const imports: any = [
  provideFirebaseApp(() => initializeApp(environment.firebase)),
  provideDatabase(() => getDatabase()),
  AppRoutingModule,
  AuthModule,
  BrowserAnimationsModule,
  BrowserModule,
  CommonsModule,
  HttpClientModule,
  NgxsModule.forRoot([
    FtlState,
    SearchPartnerSidebarState,
  ], { developmentMode: !environment.production }),
  NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
  NgxsResetPluginModule.forRoot(),
  ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
];

const API_INTERCEPTOR_BYPASS_URLS: string[] = [
  'assets',
  'vimeo',
  'amazonaws',
  'api.rozum',
];

const AUTH_TOKEN_INTERCEPTOR_BYPASS_URLS: string[] = [
  'assets',
  `${environment.api}/v1`,
  'vimeo',
  'amazonaws',
];

const providers: Provider[] = [
  { provide: APP_BASE_HREF, useValue: '/' },
  {
    provide: API_INTECEPTOR_CONFIG,
    useValue: {
      api: environment.api,
      bypass: API_INTERCEPTOR_BYPASS_URLS,
    },
  },
  {
    provide: AUTH_TOKEN_CONFIG,
    useValue: {
      jwt: JWT_TOKEN,
      bypass: AUTH_TOKEN_INTERCEPTOR_BYPASS_URLS,
    },
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: APIInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthTokenInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JSONApiDeserializerInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ProfileHashInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestCachingInterceptor,
    multi: true,
  },
  {
    provide: USER_SERVICE_TOKEN,
    useClass: UserServiceProxy,
  },
  {
    provide: SENTINEL_CONFIG,
    useValue: { environment, logErrors: true },
  },
  {
    provide: GLOBAL_ERROR_STATUS_HANDLER_TOKEN,
    useClass: GlobalErrorStatusHandler,
  },
  {
    provide: ErrorHandler,
    useClass: SentinelErrorHandler,
  },
];

@NgModule({
  imports,
  providers,
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
