import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  EventEmitter, Component, OnInit, Output, Input,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';
import { Dictionary, FormErrors } from 'asap-team/asap-tools';

// Types
import type { CheckboxItem } from '@core/types';

// Consts
import { manualAddressErrors, US_ZIP_PATTERN } from '@consts/consts';
import { identity, pickBy } from 'lodash-es';

@UntilDestroy()
@Component({
  selector: 'manual-address-form',
  templateUrl: './manual-address-form.component.html',
  styleUrls: ['./manual-address-form.component.scss'],
})
export class ManualAddressFormComponent implements OnInit {

  @Input() initialState: unknown = null;

  @Input() states: CheckboxItem[];

  @Output()
  formComplete: EventEmitter<{
    address: Dictionary;
  }> = new EventEmitter<{
    address: Dictionary;
  }>();

  form: FormGroup;

  formErrors: FormErrors = manualAddressErrors;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
    this.setInitialState();
    this.subscribeToFormChange();
  }

  private initForm(): void {
    this.form = this.fb.group({
      street: ['', [Validators.required]],
      unit: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.pattern(US_ZIP_PATTERN), Validators.required]],
      country: ['United States'],
    });
  }

  private setInitialState(): void {
    if (this.initialState) {
      this.form.patchValue(pickBy(this.initialState, identity));
      this.form.markAllAsTouched();

      if (this.form.valid) {
        this.formComplete.emit({ address: this.form.value });
      }
    }
  }

  private subscribeToFormChange(): void {
    this.form.statusChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(
          (prev: string, curr: string) => prev === 'INVALID' && curr === 'INVALID',
        ),
      )
      .subscribe(() => {
        const newValue: Dictionary = this.form.valid ? this.form.value : null;

        this.formComplete.emit({ address: newValue });
      });
  }

}
