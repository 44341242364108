import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

// Const
import { ONLY_POSITIVE_INTEGERS_PATTERN } from '@consts/regex';

@UntilDestroy()
@Component({
  selector: 'iq-from-to-filter',
  templateUrl: './iq-from-to-filter.component.html',
  styleUrls: ['./iq-from-to-filter.component.scss'],
})
export class IqFromToFilterComponent implements OnInit {

  @Input() min: number = 0;

  @Input() max: number = 100;

  @Input() filterName: string = '';

  @Input() filterLabelName: string = '';

  @Input() minStateValue: number;

  @Input() maxStateValue: number;

  @Input() minInputLabel: string = 'Min';

  @Input() maxInputLabel: string = 'Max';

  @Input() appendSelector: string = null;

  @Input() inputsMask: string = '';

  @Output() emitFilterValue: EventEmitter<{ filterName: string; value: any }> = new EventEmitter();

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      min: [this.minStateValue || null, [
        Validators.min(this.min),
        Validators.max(this.max),
        Validators.pattern(ONLY_POSITIVE_INTEGERS_PATTERN),
      ]],
      max: [this.maxStateValue || null, [
        Validators.min(this.min),
        Validators.max(this.max),
        Validators.pattern(ONLY_POSITIVE_INTEGERS_PATTERN),
        RxwebValidators.greaterThanEqualTo({ fieldName: 'min' }),
      ]],
    });

    this.formSubscribes();
  }

  clear(): void {
    this.form.reset({ min: null, max: null }, { emitEvent: false });
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  private formSubscribes(): void {
    const minControl: AbstractControl = this.form.get('min');
    const maxControl: AbstractControl = this.form.get('max');

    minControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map((min: string) => (+min || null)),
        filter(() => minControl.valid),
        untilDestroyed(this),
      )
      .subscribe((value: number) => {
        this.emitFilterValue.emit({ filterName: `${this.filterName}_from`, value });
      });

    maxControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map((max: string) => (+max || null)),
        filter(() => maxControl.valid),
        untilDestroyed(this),
      )
      .subscribe((value: number) => {
        this.emitFilterValue.emit({ filterName: `${this.filterName}_to`, value });
      });
  }

}
