import { FormErrors } from 'asap-team/asap-tools';

// Consts
import { apiError, required, invalidPhone } from './conditions';

export const createLeadErrors: FormErrors = {
  email: [
    required,
    {
      name: 'pattern',
      text: 'Email is invalid',
      rules: ['touched', 'dirty'],
    },
    apiError,
  ],
  phone: [
    invalidPhone,
    apiError,
  ],
  owner_name: [
    required,
    {
      name: 'minlength',
      text: 'Min length is 1',
      rules: ['touched', 'dirty'],
    },
    apiError,
  ],
  home_value: [
    required,
    apiError,
  ],
  open_lien_amount: [
    required,
    apiError,
  ],
  interest_rate: [
    required,
    apiError,
  ],
  year_built: [
    {
      name: 'minNumber',
      text: 'Must be greater than or equal to 1900',
      rules: ['touched', 'dirty'],
    },
    apiError,
  ],
  home_sq_ft: [
    apiError,
  ],
  bedrooms: [
    apiError,
  ],
  bathrooms: [
    apiError,
  ],
  loan_amortization_period: [
    required,
    apiError,
  ],
  loan_type: [
    required,
    apiError,
  ],
  loan_date: [
    apiError,
  ],
  sale_date: [
    apiError,
  ],
  insurance_amount: [
    required,
    apiError,
  ],
  global: [],
};
