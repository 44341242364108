import {
  Component,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, filter } from 'lodash-es';
import { chain } from 'asap-team/asap-tools';

import type { ZipCode } from '@core/types';

// Services
import { ZipCodesService } from '@core/services/zip-codes/zip-codes.service';

@UntilDestroy()
@Component({
  selector: 'iq-area-filter',
  templateUrl: './iq-area-filter.component.html',
  styleUrls: ['./iq-area-filter.component.scss'],
})
export class IqAreaFilterComponent {

  @ViewChild('form') form: ElementRef;

  @Input() filterName: string;

  @Input() type: string;

  @Input() stateValue: any;

  @Output() emitFilterValue: EventEmitter<{ filterName: string; value: any }> = new EventEmitter();

  areas: ZipCode[] = [];

  isLoading: boolean = true;

  constructor(
    private zipCodesService: ZipCodesService,
  ) { }

  private reset(): void {
    if (this.form) {
      this.form.nativeElement.reset();
    }

    this.areas = map(this.areas, (area: ZipCode) => {
      // eslint-disable-next-line no-param-reassign
      area.selected = false;

      return area;
    });
  }

  get isShowLabel(): boolean {
    const selected: ZipCode[] = filter(this.areas, (area: ZipCode) => area.selected);
    const isAllSelected: boolean = selected.length === this.areas.length;

    return !this.selected || isAllSelected;
  }

  get selected(): string {
    return chain(this.areas, { filter, map })
      .filter((area: ZipCode) => area.selected)
      .map((area: ZipCode) => area.zip_code)
      .value()
      .join(', ');
  }

  lookup(): void {
    if (this.areas.length) { return; }

    this
      .zipCodesService
      .lookup(this.type)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        (response: ZipCode[]) => {
          this.areas = response;
          this.isLoading = false;
        },
      );
  }

  select(selectedAreas: ZipCode): void {
    this.areas = map(this.areas, (area: ZipCode) => {
      if (selectedAreas.id === area.id) {
        // eslint-disable-next-line no-param-reassign
        area.selected = !area.selected;
      }

      return area;
    });

    const selected: string = chain(this.areas, { filter, map })
      .filter((app: ZipCode) => app.selected)
      .map((app: ZipCode) => app.zip_code)
      .value()
      .join(',');

    this.emitFilterValue.emit({
      filterName: this.filterName,
      value: selected,
    });
  }

  clear(): void {
    this.reset();
    this.emitFilterValue.emit({
      filterName: this.filterName,
      value: null,
    });
  }

}
