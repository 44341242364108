<h2 class="ftl-title m-b-1x text-center">Hi {{ profile?.name }} 👋, welcome to myhomeIQ</h2>
<p class="ftl-regular-text m-b-5x text-center">Create a password to access your account.</p>
<form class="password-form" [formGroup]="form" [class.match-error]="error === 'match'" (ngSubmit)="submitPasswordStep()">
  <iq-form-control-password class="m-b-6"
                            formControlName="password"
                            placeholder="*******"></iq-form-control-password>
  <div class="hint m-b-2x">
    Your password must be at least 8 characters long, include both upper and lower case letters, at least one number, and one special character
  </div>
  <iq-form-control-password class="m-b-2x"
                            formControlName="password_confirmation"
                            placeholder="*******"></iq-form-control-password>
  
  <div class="password-form__errors">
    <ng-container *ngIf="!form.pristine && error">
      <span *ngIf="error === 'minlength'">At least 6 characters.</span>
      <span *ngIf="error === 'required'">Fields are required.</span>
      <span *ngIf="error === 'pattern'">
        At least one uppercase letter, lowercase letter, one digit, and a special character
      </span>
      <span *ngIf="error === 'match'">Password does not match.</span>
    </ng-container>
  </div>
  <iq-button class="block"
             type="submit"
             label="Continue"
             [busy$]="action$">
  </iq-button>
</form>
